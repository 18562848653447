import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Dropdown, message, Modal, Radio } from "antd";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, updateUserQuota, updateUserPassword, deleteUser, updateUserStatus } from "@/store/modules/admin";

export const UserTable = () => {
    const dispatch = useDispatch();
    const data = (useSelector((state) => state.admin.users) || [])
        .map((item, index) => ({
            ...item,
            key: index,
            statusText: item.status === 0 ? "启用" : item.status === 1 ? "禁用" : "管理员",
        }))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    const [modalState, setModalState] = useState(null); // Unified state management

    const searchInput = useRef(null);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`搜索 ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        重置
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
        onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const handleAction = async (key, record) => {
        switch (key) {
            case "change-password":
                setModalState({ type: "password", user: record, value: "" });
                break;
            case "increase-quota":
                setModalState({ type: "quota", user: record, value: "" });
                break;
            case "update-status":
                setModalState({ type: "status", user: record, value: record.status });
                break;
            case "delete-account":
                try {
                    await dispatch(deleteUser(record.id)).unwrap();
                    message.success("账户删除成功！");
                } catch (err) {
                    console.error("删除账户失败", err);
                    message.error("删除账户失败，请稍后重试！");
                }
                break;
            default:
                break;
        }
    };

    const handleModalOk = async () => {
        if (modalState?.type === "quota" && !modalState.value) {
            message.warning("请输入有效的配额值！");
            return;
        }

        try {
            if (modalState.type === "quota") {
                await dispatch(updateUserQuota({ user_id: modalState.user.id, quota: parseInt(modalState.value, 10) })).unwrap();
                message.success("配额更新成功！");
            } else if (modalState.type === "password") {
                await dispatch(updateUserPassword({ user_id: modalState.user.id, password: modalState.value })).unwrap();
                message.success("密码更新成功！");
            } else if (modalState.type === "status") {
                await dispatch(updateUserStatus({ user_id: modalState.user.id, status: modalState.value })).unwrap();
                message.success("状态更新成功！");
            }
            setModalState(null);
        } catch (err) {
            console.error("操作失败", err);
            message.error("操作失败，请稍后重试！");
        }
    };
    console.log(JSON.stringify(data, null, 2))
    const columns = [
        {
            title: "账户",
            dataIndex: "username",
            key: "username",
            ...getColumnSearchProps("username"),
        },
        {
            title: "账号",
            dataIndex: "sub_user_quantity",
            key: "sub_user_quantity",
            sorter: (a, b) => a.sub_user_quantity - b.sub_user_quantity,
        },
        {
            title: "配额",
            dataIndex: "quota",
            key: "quota",
            sorter: (a, b) => a.quota - b.quota,
        },
        {
            title: "状态",
            dataIndex: "statusText",
            key: "status",
        },
        {
            title: "操作",
            key: "actions",
            render: (_, record) => {
                const menuItems = [
                    { key: "change-password", label: "修改密码" },
                    { key: "increase-quota", label: "修改配额" },
                    { key: "update-status", label: "修改状态" },
                    { type: "divider" },
                    { key: "delete-account", label: <span style={{ color: "red" }}>删除账户</span> },
                ];
                return (
                    <Dropdown menu={{ items: menuItems, onClick: ({ key }) => handleAction(key, record) }} trigger={["click"]}>
                        <MoreOutlined style={{ fontSize: 20, cursor: "pointer" }} />
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <Table columns={columns} dataSource={data} loading={!data.length} pagination={{ hideOnSinglePage: true }} />
            <Modal
                title={
                    modalState?.type === "quota"
                        ? "修改配额"
                        : modalState?.type === "password"
                        ? "修改密码"
                        : "修改状态"
                }
                open={!!modalState}
                onOk={handleModalOk}
                onCancel={() => setModalState(null)}
            >
                {modalState?.type === "status" ? (
                    <Radio.Group
                        value={modalState.value}
                        onChange={(e) => setModalState((prev) => ({ ...prev, value: e.target.value }))}
                    >
                        <Radio value={0}>启用</Radio>
                        <Radio value={1}>禁用</Radio>
                        <Radio value={2}>管理员</Radio>
                    </Radio.Group>
                ) : (
                    <Input
                        type={modalState?.type === "quota" ? "number" : "password"}
                        placeholder={modalState?.type === "quota" ? "请输入配额" : "请输入新密码"}
                        value={modalState?.value || ""}
                        onChange={(e) => setModalState((prev) => ({ ...prev, value: e.target.value }))}
                    />
                )}
            </Modal>
        </>
    );
};

import { SubUserApiDefinition } from "@/domains/subUser";
import { createRequest } from "@/utils/request";

export const ApiGetServers = (username, password) => createRequest(SubUserApiDefinition.getServers)({ data: { username, password } });

export const ApiBind = (parent_id, username, password, token, server_name, server_id, uid) =>
    createRequest(SubUserApiDefinition.bind)({ data: { parent_id, username, password, token, server_name, server_id, uid } });

export const ApiDeleteSubUser = (sub_user_id) => createRequest(SubUserApiDefinition.delete)({ url: { id: sub_user_id } });

export const ApiChangeSubUserPassword = (sub_user_id, old_password, password) =>
    createRequest(SubUserApiDefinition.changePassword)({ url: { id: sub_user_id }, data: { old_password, password } });

export const ApiGetSetting = (sub_user_id) => createRequest(SubUserApiDefinition.getSetting)({ url: { id: sub_user_id } });

export const ApiSaveSetting = (sub_user_id, setting) => createRequest(SubUserApiDefinition.saveSetting)({ url: { id: sub_user_id }, data: { setting } });

import React, { useEffect } from "react";
import NavBar from "@/components/Navbar";
import HomeCards from "@/components/HomeCards";
import "./style.css";
import {  GetQuota } from "@/store/modules/user";
import { fetchSubUsers, subscribeToContainerStatus } from "@/store/modules/admin";
import { useDispatch, useSelector } from "react-redux";

export const AdminSubUsers = () => {
    const dispatch = useDispatch();

    const { sub_users } = useSelector((state) => state.admin);

    useEffect(() => {
        const loadAccounts = async () => {
            try {
                await dispatch(fetchSubUsers()).unwrap();
            } catch (err) {
                console.error("加载账户数据失败，请稍后重试！", err);
            }

            try {
                await dispatch(GetQuota()).unwrap();
            } catch (err) {
                console.error("加载配额失败，请稍后重试！", err);
            }
        };

        loadAccounts();
    }, [dispatch]);
    
    let eventSourceInstance = null;

    useEffect(() => {
        if (eventSourceInstance) {
            eventSourceInstance.close();
        }
    
        eventSourceInstance = subscribeToContainerStatus(dispatch);
    
        return () => {
            if (eventSourceInstance) {
                console.log("清理资源");
                eventSourceInstance.close();
                eventSourceInstance = null;
            }
        };
    }, [dispatch]);

    return (
        <div className="sub-users-container">
            {/* 顶部导航 */}
            <NavBar />

            {/* Account Cards */}
            <HomeCards sub_users={sub_users} is_admin={true}/>
        </div>
    );
};

import React from "react";
import { Switch, Input } from "antd";
import { Form } from "antd-mobile";
import { useDispatch } from "react-redux";
import { updateSetting } from "@/store/modules/setting";

const CaveTab = ({ setting }) => {
    const { cave: config } = setting;
    const category = "cave";
    const dispatch = useDispatch();

    const handleChange = (key, value) => {
        dispatch(updateSetting({ category, key, value }));
    };

    const handleSubChange = (field, subField, value) => {
        const updatedField = { ...config[field], [subField]: value };
        dispatch(updateSetting({ category, key: field, value: updatedField }));
    };

    const TODO = <span style={{ fontStyle: "italic", color: "#888" }}>TODO</span>;

    return (
        <Form layout="horizontal">
            <h4>聚灵阵设置</h4>
            <Form.Item label="自动聚灵" valuePropName="checked">
                <Switch checked={config.gatherEnergy?.enabled ?? false} onChange={(checked) => handleSubChange("gatherEnergy", "enabled", checked)} />
            </Form.Item>
            <Form.Item label="自动入阵" valuePropName="checked">
                <Switch checked={config.gatherEnergy?.robPos ?? false} onChange={(checked) => handleSubChange("gatherEnergy", "robPos", checked)} />
            </Form.Item>
            <Form.Item label="能量阈值" help="抢占的最低能量值">
                <Input
                    type="number"
                    value={config.gatherEnergy?.threshold ?? 0}
                    onChange={(e) => handleSubChange("gatherEnergy", "threshold", e.target.value)}
                />
            </Form.Item>

            <h4>道途设置</h4>
            <Form.Item label="道途开启" valuePropName="checked">
                <Switch checked={config.profession?.enabled ?? false} onChange={(checked) => handleSubChange("profession", "enabled", checked)} />
            </Form.Item>
            <Form.Item label="挑战道途" valuePropName="checked">
                <Switch checked={config.profession?.challenge ?? false} onChange={(checked) => handleSubChange("profession", "challenge", checked)} />
            </Form.Item>

            <h4>宗门设置</h4>
            <Form.Item label="自动训练" valuePropName="checked">
                <Switch checked={config.pupil?.enabled ?? false} onChange={(checked) => handleSubChange("pupil", "enabled", checked)} />
            </Form.Item>
            <Form.Item label="扔掉弟子" valuePropName="checked" help="自动丢弃弟子至妖盟">
                <Switch checked={config.pupil?.abandon ?? false} onChange={(checked) => handleSubChange("pupil", "abandon", checked)} />
            </Form.Item>

            <h4>乾坤袋设置</h4>
            <Form.Item label="使用灵芝" help="自动使用万年灵芝" valuePropName="checked">
                <Switch checked={config.bag?.useLingzhi ?? false} onChange={(checked) => handleSubChange("bag", "useLingzhi", checked)} />
            </Form.Item>
            <Form.Item label="使用净瓶水" help="自动使用净瓶水" valuePropName="checked">
                <Switch checked={config.bag?.uesJingPingShui ?? false} onChange={(checked) => handleSubChange("bag", "uesJingPingShui", checked)} />
            </Form.Item>
        </Form>
    );
};

export default CaveTab;

import Cookies from "js-cookie";

const key = "xddq";

export const auth = {
    get() {
        return Cookies.get(key);
    },

    set(value) {
        return Cookies.set(key, value);
    },

    delete() {
        return Cookies.remove(key);
    },
};

import React from "react";
import { Form } from "antd-mobile";
import { useDispatch } from "react-redux";
import { updateSetting } from "@/store/modules/setting";
import { mallItems } from "../constants"; // 引入商店商品列表
import { ShuttleBox } from "@/components/ShuttleBox"; // 使用 ShuttleBox 组件

const MallTab = ({ setting }) => {
    const { mall: config } = setting; // 从 Redux 中提取 Mall 配置
    const category = "mall";
    const dispatch = useDispatch();

    const handleChange = (key, value) => {
        dispatch(updateSetting({ category, key, value }));
    };
    const TODO = <span style={{ fontStyle: "italic", color: "#888" }}>TODO</span>;
    return (
        <Form layout="horizontal">
            <Form.Header>商店购物</Form.Header>
            
            <ShuttleBox
                availableItems={mallItems.filter((item) => !config.buyGoodLists?.includes(item.id))}
                selectedItems={config.buyGoodLists?.map((id) => mallItems.find((item) => item.id === id)) || []}
                labels={{ available: "可购买商品", selected: "已选商品" }}
                helpText={{ selected: "购买优先级按照从上到下排列" }}
                onChange={(val) => handleChange("buyGoodLists", val)}
            />

            <Form.Header>弹窗购物</Form.Header>
            <Form.Item label="弹窗购物">{TODO}</Form.Item>
        </Form>
    );
};

export default MallTab;

import React from "react";
import { Switch, Select, Input, Radio, message } from "antd";
import { Form } from "antd-mobile";
import { useDispatch } from "react-redux";
import { updateSetting } from "@/store/modules/setting";
import { skillDict, avatarOptions, daysOfWeek } from "../constants";

const ChallengeTab = ({ setting }) => {
    const { challenge: config } = setting;
    const category = "challenge";
    const dispatch = useDispatch();

    const handleChange = (key, value) => {
        dispatch(updateSetting({ category, key, value }));
    };

    const handleSubChange = (field, subField, value) => {
        const updatedField = { ...config[field], [subField]: value };
        dispatch(updateSetting({ category, key: field, value: updatedField }));
    };

    const TODO = <span style={{ fontStyle: "italic", color: "#888" }}>TODO</span>;
    const renderSeparation = (label, key, helpText, parentField) => (
        <Form.Item label={label} help={helpText}>
            <Radio.Group value={config[parentField]?.[key] || 0} onChange={(e) => handleSubChange(parentField, key, e.target.value)}>
                {avatarOptions.map((option) => (
                    <Radio.Button key={option.value} value={option.value}>
                        {option.label}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </Form.Item>
    );

    return (
        <Form layout="horizontal">
            <h4>常规设置</h4>
            {/* 冒险开关 */}
            <Form.Item label="冒险开关" valuePropName="checked">
                <Switch checked={config.common?.chapter ?? false} onChange={(checked) => handleSubChange("common", "chapter", checked)} />
            </Form.Item>

            {/* 镇妖塔 */}
            <Form.Item label="镇妖塔" valuePropName="checked">
                <Switch checked={config.common?.tower ?? false} onChange={(checked) => handleSubChange("common", "tower", checked)} />
            </Form.Item>

            {/* 镇妖塔技能 */}
            <Form.Item label="镇妖塔技能" help="最多选择 5 个技能">
                <Select
                    mode="multiple"
                    value={config.common?.towerSkills ?? []}
                    onChange={(value) => {
                        if (value.length <= 5) {
                            handleSubChange("common", "towerSkills", value);
                        } else {
                            message.error("最多选择5个技能!");
                        }
                    }}
                    style={{ width: "100%" }}
                    maxTagCount={5}
                    placeholder="请选择技能"
                >
                    {Object.keys(skillDict).map((key) => (
                        <Select.Option key={key} value={parseInt(key)}>
                            {skillDict[key]}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {/* 六道秘境 */}
            <Form.Item label="六道秘境" valuePropName="checked">
                <Switch checked={config.common?.secretTower ?? false} onChange={(checked) => handleSubChange("common", "secretTower", checked)} />
            </Form.Item>

            {/* 结果显示 */}
            <Form.Item label="显示结果" valuePropName="checked">
                <Switch checked={config.common?.showResult ?? false} onChange={(checked) => handleSubChange("common", "showResult", checked)} />
            </Form.Item>

            {/* 推图次数 */}
            <Form.Item label="推图次数" extra="次" help="0 为不挑战">
                <Input type="number" value={config.common?.num ?? 0} onChange={(e) => handleSubChange("common", "num", parseInt(e.target.value || 0, 10))} />
            </Form.Item>

            {/* 仅当hasSeparation为 true 时显示 */}
            {setting.basic.hasSeparation && renderSeparation("推图分身", "index", "推图使用的分身", "common")}

            <h4>异兽入侵</h4>
            {/* 异兽入侵开关 */}
            <Form.Item label="异兽入侵" valuePropName="checked">
                <Switch checked={config.invade?.enabled ?? false} onChange={(checked) => handleSubChange("invade", "enabled", checked)} />
            </Form.Item>

            {/* 异兽分身 */}
            {setting.basic.hasSeparation && renderSeparation("异兽分身", "index", "异兽入侵分身", "invade")}

            <h4>群英榜设置</h4>
            {/* 随机打榜 */}
            <Form.Item label="随机冲榜" help="每天都会随机瞎打" valuePropName="checked">
                <Switch checked={config.herorank?.fightDaily ?? false} onChange={(checked) => handleSubChange("herorank", "fightDaily", checked)} />
            </Form.Item>

            {/* 周一冲榜 */}
            <Form.Item label="周一冲榜" help="周一00:05分冲击群英榜, 请保证拥有足够的体力" valuePropName="checked">
                <Switch checked={config.herorank?.robRanking ?? false} onChange={(checked) => handleSubChange("herorank", "robRanking", checked)} />
            </Form.Item>

            {/* 购买体力 */}
            <Form.Item label="购买体力" help="选择需要购买体力的日子">
                <Select
                    mode="multiple"
                    value={(config.herorank?.buyTickets ?? []).map((selected, index) => (selected ? daysOfWeek[index] : null)).filter(Boolean)}
                    style={{ width: "100%" }}
                    onChange={(values) => {
                        const newBuyTickets = daysOfWeek.map((day) => values.includes(day));
                        handleSubChange("herorank", "buyTickets", newBuyTickets);
                    }}
                    placeholder="请选择需要购买体力的日子"
                >
                    {daysOfWeek.map((day, index) => (
                        <Select.Option key={index} value={day}>
                            {day}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <h4>斗法设置</h4>
            {/* 自动斗法 */}
            <Form.Item label="自动斗法" valuePropName="checked">
                <Switch checked={config.fight?.enabled ?? false} onChange={(checked) => handleSubChange("fight", "enabled", checked)} />
            </Form.Item>

            {/* 人机模式 */}
            <Form.Item label="人机模式" valuePropName="checked">
                <Switch checked={config.fight?.robotOnly ?? false} onChange={(checked) => handleSubChange("fight", "robotOnly", checked)} />
            </Form.Item>

            {/* 安全词 */}
            <Form.Item label="安全词" help="防止误伤自己人">
                <Input value={config.fight?.safeWord ?? ""} onChange={(e) => handleSubChange("fight", "safeWord", e.target.value)} />
            </Form.Item>

            {/* 追杀词 */}
            <Form.Item label="追杀词" help="锁定目标追杀">
                <Input value={config.fight?.chaseWord ?? ""} onChange={(e) => handleSubChange("fight", "chaseWord", e.target.value)} />
            </Form.Item>

            {/* 分身设置 */}
            {setting.basic.hasSeparation && renderSeparation("斗法分身", "index", "斗法使用的分身", "fight")}

            <h4>其他设置</h4>
            {/* 挑战妖王 */}
            <Form.Item label="挑战妖王" valuePropName="checked">
                <Switch checked={config.wildBoss ?? false} onChange={(checked) => handleChange("wildBoss", checked)} />
            </Form.Item>

            {/* 星宿试炼 */}
            <Form.Item label="星宿试炼" valuePropName="checked">
                <Switch checked={config.starTrial ?? false} onChange={(checked) => handleChange("starTrial", checked)} />
            </Form.Item>

            {/* 征战诸天 */}
            <Form.Item label="征战诸天" valuePropName="checked">
                {TODO}
            </Form.Item>
        </Form>
    );
};

export default ChallengeTab;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiGetUsers, ApiUserDelete, ApiUpdateQuota, ApiUpdateStatus, ApiUpdatePassword, ApiGetSubUsers } from "@/api/useAdmin";
import { storage } from "@/utils/storage";
import { Logout, GetQuota } from "@/store/modules/user";
import store from "@/store";
import { StatusStream } from "@/api/useContainer";

// 获取所有用户的异步 thunk
export const fetchUsers = createAsyncThunk("admin/fetchUsers", async () => {
    const response = await ApiGetUsers();
    return response;
});

// 获取所有子用户的异步 thunk
export const fetchSubUsers = createAsyncThunk("admin/fetchSubUsers", async () => {
    const response = await ApiGetSubUsers();
    return response;
});

// 删除用户的异步 thunk
export const deleteUser = createAsyncThunk("admin/deleteUser", async (user_id) => {
    await ApiUserDelete(user_id);
    if (user_id === storage.get("id")) {
        store.dispatch(Logout());
    }
    return user_id;
});

// 更新用户配额的异步 thunk
export const updateUserQuota = createAsyncThunk("admin/updateUserQuota", async ({ user_id, quota }) => {
    await ApiUpdateQuota(user_id, quota);
    if (user_id === storage.get("id")) {
        store.dispatch(GetQuota());
    }
    return { user_id, quota };
});

// 更新用户状态的异步 thunk
export const updateUserStatus = createAsyncThunk("admin/updateUserStatus", async ({ user_id, status }) => {
    await ApiUpdateStatus(user_id, status);
    return { user_id, status };
});

// 更新用户密码的异步 thunk
export const updateUserPassword = createAsyncThunk("admin/updateUserPassword", async ({ user_id, password }) => {
    await ApiUpdatePassword(user_id, password);
    return { user_id, password };
});

// subscribeToContainerStatus
export const subscribeToContainerStatus = (dispatch) => {
    const handleData = (data) => {
        if (JSON.parse(data) === null) return;

        const parsedData = JSON.parse(data);

        const items = Array.isArray(parsedData) ? parsedData : [parsedData];

        items.forEach((item) => {
            const isValid = item?.id && typeof item.status === "string";
            if (isValid) {
                console.log("接收到状态更新:", item);
                dispatch(updateStatus(item));
            } else {
                console.warn("无效的数据格式:", item);
            }
        });
    };
    
    const handleError = (error) => {
        console.error("状态流错误", error);
    };

    return StatusStream("all", handleData, handleError);
};

const adminSlice = createSlice({
    name: "admin",
    initialState: {
        users: [],
        sub_users: [],
    },
    reducers: {
        resetState: (state) => {
            state.users = [];
            state.sub_users = [];
        },
        updateStatus: (state, action) => {
            const { id, status } = action.payload;
            const subUser = state.sub_users.find((user) => user.id === id);
            if (subUser) {
                subUser.status = status;
            }
        },
    },
    extraReducers: (builder) => {
        // 处理获取用户列表的成功情况
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.users = action.payload;
        });

        // 处理获取子用户列表的成功情况
        builder.addCase(fetchSubUsers.fulfilled, (state, action) => {
            state.sub_users = action.payload;
        });

        // 处理删除用户的成功情况，从 users 列表中移除对应的用户
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.users = state.users.filter(user => user.id!== action.payload);
        });

        // 处理更新用户配额的成功情况，更新对应用户的配额信息
        builder.addCase(updateUserQuota.fulfilled, (state, action) => {
            const { user_id, quota } = action.payload;
            const user = state.users.find(u => u.id === user_id);
            if (user) {
                user.quota = quota;
            }
        });

        // 处理更新用户状态的成功情况，更新对应用户的状态信息
        builder.addCase(updateUserStatus.fulfilled, (state, action) => {
            const { user_id, status } = action.payload;
            const user = state.users.find(u => u.id === user_id);
            if (user) {
                user.status = status;
            }
        });
    }
});

export const { resetState, updateStatus } = adminSlice.actions;
export default adminSlice.reducer;
import React from "react";
import { Button, List } from "antd-mobile"; // 引入 List 组件
import NavBar from "@/components/Navbar";
import { useNavigate } from "react-router-dom";
import { UserOutlined, DiscordOutlined } from "@ant-design/icons"; // 引入图标
import { useDispatch } from "react-redux";
import { Logout } from "@/store/modules/user";
import "./style.css"

export const AdminHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 登出逻辑
    const handleLogout = () => {
        dispatch(Logout());
    };

    // 跳转到子账户管理页面
    const goToSubUsers = () => {
        navigate("/admin/home/sub-users");
    };

    // 跳转到用户管理页面
    const goToUsers = () => {
        navigate("/admin/home/users");
    };

    return (
        <div className="admin-home-container">
            <NavBar />

            {/* 添加列表进行页面导航 */}
            <List header="Admin管理" >
                <List.Item prefix={<UserOutlined />} onClick={goToUsers}>
                    用户管理
                </List.Item>

                <List.Item prefix={<DiscordOutlined />} onClick={goToSubUsers}>
                    游戏状态
                </List.Item>
            </List>

            {/* 添加一个登出按钮 */}
            <Button block color="danger" style={{ marginTop: "16px" }} onClick={handleLogout}>
                退出登录
            </Button>

        </div>
    );
};

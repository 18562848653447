import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiGetSetting, ApiSaveSetting } from "@/api/useSubUser";

// 获取子账户配置
export const GetSetting = createAsyncThunk("setting/getSetting", async (subUserId, { rejectWithValue }) => {
    try {
        const response = await ApiGetSetting(subUserId);
        return { setting: response };
    } catch (error) {
        return rejectWithValue(error.message || "Failed to fetch setting");
    }
});

// 保存子账户配置
export const SaveSetting = createAsyncThunk("setting/saveSetting", async ({ subUserId, setting }, { rejectWithValue }) => {
    try {
        await ApiSaveSetting(subUserId, setting);
        return { setting };
    } catch (error) {
        return rejectWithValue(error.message || "Failed to save setting");
    }
});

const settingSlice = createSlice({
    name: "setting",
    initialState: {
        // 临时setting 防止报错的
        setting: {
            activity: {
                autoFreePurchase: true,
                castSword: {
                    enabled: false,
                    showResult: false,
                    useItem: false
                },
                manHuang: {
                    enabled: false,
                    showResult: false,
                    useItem: false
                },
                skyTrade: {
                    enabled: true,
                    useItem: false
                },
                xianyuActivityIDs: []
            },
            basic: {
                reconnectInterval: 300000,
                autoDuJie: false,
                autoUpgradeCloud1: true,
                autoUpgradeCloud2: true,
                autoXinMoJie: false,
                chopTree: {
                    enabled: false,
                    levelOffset: 5,
                    main: {
                        primaryAttribute: [6, 7],
                        secondaryAttribute: [14]
                    },
                    probOffset: 0.2,
                    probOffsetLowLv: 0.3,
                    quality: 11,
                    separation: [
                        {
                            primaryAttribute: [9],
                            secondaryAttribute: [11]
                        },
                        {
                            primaryAttribute: [6, 7],
                            secondaryAttribute: [14]
                        },
                        {
                            primaryAttribute: [8],
                            secondaryAttribute: [12]
                        }
                    ],
                    showResult: true,
                    stop: {
                        doNum: -1,
                        level: -1,
                        stopNum: 100
                    }
                },
                defaultIndex: 0,
                hasSeparation: true,
                pet: {
                    autoCapture: false,
                    enabled: false,
                    wishPets: [
                        114001,
                        114007,
                        115001,
                        115002,
                        115003,
                        115004,
                        115005
                    ]
                },
                talent: {
                    enabled: false,
                    main: {
                        attribute: [4, 12, 14],
                        skillId: [50005]
                    },
                    quality: 5,
                    separation: [
                        {
                            attribute: [4, 11, 14],
                            skillId: [50002]
                        },
                        {
                            attribute: [4, 12, 14],
                            skillId: [50005]
                        },
                        {
                            attribute: [4, 11, 12],
                            skillId: [50004]
                        }
                    ],
                    showResult: true,
                    stop: {
                        doNum: -1,
                        stopNum: 10
                    }
                }
            },
            cave: {
                bag: {
                    useJingPingShui: false,
                    useLingzhi: true
                },
                gatherEnergy: {
                    enabled: true,
                    robPos: true,
                    threshold: 400
                },
                profession: {
                    challenge: false,
                    enabled: false
                },
                pupil: {
                    abandon: false,
                    enabled: false
                }
            },
            challenge: {
                common: {
                    chapter: true,
                    index: 0,
                    num: 999,
                    secretTower: true,
                    showResult: false,
                    tower: true,
                    towerSkills: [1017, 1018, 1023, 1001]
                },
                fight: {
                    chaseWord: "",
                    enabled: true,
                    index: 0,
                    robotOnly: true,
                    safeWord: ""
                },
                herorank: {
                    buyTickets: [true, true, false, false, false, false, false],
                    fightDaily: false,
                    robRanking: false
                },
                invade: {
                    enabled: false,
                    index: 2
                },
                skyWar: {
                    enabled: false,
                    index: 0
                },
                starTrial: true,
                wildBoss: true
            },
            homeland: {
                autoHarvest: false,
                collectionHours: [],
                enabled: false,
                flashSteal: false,
                rules: [10033, 10012, 10032, 10011, 10031]
            },
            mall: {
                buyGoodLists: [
                    230000011,
                    230000002,
                    230000006,
                    230000014,
                    230000001,
                    230000005
                ],
                popupGoodLists: []
            },
            union: {
                bargain: {
                    num: 3,
                    price: 50
                },
                boss: {
                    enabled: true,
                    index: 0,
                    scheduleHour: 0
                },
                duel: false,
                suppressDemon: false
            }
        },
        isLoading: false,
        error: null,
    },
    reducers: {
        updateSetting: (state, action) => {
            const { category, key, value } = action.payload;

            if (!state.setting[category]) {
                state.setting[category] = {};
            }

            state.setting[category][key] = value;
        },
    },
    extraReducers: (builder) => {
        builder
            // 获取子账户配置
            .addCase(GetSetting.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(GetSetting.fulfilled, (state, action) => {
                const { setting } = action.payload;
                // 更新临时setting
                state.setting = setting;
                state.isLoading = false;
            })
            .addCase(GetSetting.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    },
});

export const { updateSetting } = settingSlice.actions;
export default settingSlice.reducer;

import React, { useState, useEffect } from "react";
import { Tabs, message, Spin } from "antd";
import NavBar from "@/components/Navbar";
import { FloatButton } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import "./style.css";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { GetSetting, SaveSetting } from "@/store/modules/setting";
import { useSearchParams } from "react-router-dom";
// Tabs
import BasicTab from "./tabs/basic";
import ChallengeTab from "./tabs/challenge";
import UnionTab from "./tabs/union";
import HomelandTab from "./tabs/homeland";
import CaveTab from "./tabs/cave";
import ActivityTab from "./tabs/activity";
import MallTab from "./tabs/mall";

export const Setting = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const dispatch = useDispatch();
    const { setting, isLoading, isSaving } = useSelector((state) => state.setting);

    const [activeIndex, setActiveIndex] = useState(0);
    const [tabBarGutter, setTabBarGutter] = useState(15);

    // 初始化获取配置
    useEffect(() => {
        const loadAccounts = async () => {
            dispatch(GetSetting(id));
        }
        loadAccounts();
    }, [id, dispatch]);

    // 动态计算 Tab 间距
    useEffect(() => {
        const calculateTabBarGutter = () => {
            const gutter = window.innerWidth > 768 ? 30 : 15;
            setTabBarGutter(gutter);
        };

        calculateTabBarGutter();
        window.addEventListener("resize", calculateTabBarGutter);

        return () => {
            window.removeEventListener("resize", calculateTabBarGutter);
        };
    }, []);

    // 保存配置
    const handleSave = async () => {
        if (isSaving) return;

        try {
            await dispatch(SaveSetting({ subUserId: id, setting: setting })).unwrap();
            message.success("设置已保存");
        } catch (error) {
            message.error("保存失败：" + error);
        }
    };

    const tabs = [
        { key: "basic", title: "基础", content: <BasicTab setting={setting}/> },
        { key: "challenge", title: "挑战", content: <ChallengeTab setting={setting} category="challenge" /> },
        { key: "union", title: "妖盟", content: <UnionTab setting={setting} category="union" /> },
        { key: "homeland", title: "福地", content: <HomelandTab setting={setting} category="homeland" /> },
        { key: "cave", title: "洞天", content: <CaveTab setting={setting} category="cave" /> },
        { key: "activity", title: "活动", content: <ActivityTab setting={setting} category="activity" /> },
        { key: "mall", title: "购物", content: <MallTab setting={setting} category="mall" /> },
    ];

    return (
        <div>
            <NavBar />
            <div className="setting-container">
                {isLoading ? (
                    <Spin size="large" />
                ) : (
                    <div className="setting-tabs">
                        <Tabs
                            activeKey={tabs[activeIndex].key}
                            onChange={(key) => {
                                const index = tabs.findIndex((item) => item.key === key);
                                setActiveIndex(index);
                            }}
                            tabBarGutter={tabBarGutter}
                            style={{ marginTop: "-20px" }}
                        >
                            {tabs.map((item) => (
                                <Tabs tab={item.title} key={item.key}>
                                    {item.content}
                                </Tabs>
                            ))}
                        </Tabs>
                    </div>
                )}

                <FloatButton
                    icon={<SaveOutlined />}
                    type="primary"
                    style={{
                        "--initial-position-bottom": "36px",
                        "--initial-position-right": "36px",
                        zIndex: 1000,
                        opacity: isSaving ? 0.5 : 1,
                        pointerEvents: isSaving ? "none" : "auto",
                    }}
                    onClick={handleSave}
                />
            </div>
        </div>
    );
};

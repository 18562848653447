import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export const Unauthorized = () => {
    const navigate = useNavigate();

    return (
        <Result
            status="403"
            title="403"
            subTitle="您没有权限查看此页面，请联系管理员获取权限。"
            extra={
                <Button type="primary" onClick={() => navigate("/home")}>
                    回首页
                </Button>
            }
        />
    );
};

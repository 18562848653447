import React from "react";
import { Switch, Select } from "antd";
import { Form } from "antd-mobile";
import { useDispatch } from "react-redux";
import { updateSetting } from "@/store/modules/setting";
import { activityDict } from "../constants";

const ActivityTab = ({ setting }) => {
    const { activity: config } = setting; // 从 Redux 中提取 Activity 设置
    const category = "activity";
    const dispatch = useDispatch();

    const handleChange = (key, value) => {
        dispatch(updateSetting({ category, key, value }));
    };

    const handleSubChange = (field, subField, value) => {
        const updatedField = { ...config[field], [subField]: value };
        dispatch(updateSetting({ category, key: field, value: updatedField }));
    };

    return (
        <Form layout="horizontal">
            <h4>活动设置</h4>

            {/* 自动领取免费道具 */}
            <Form.Item label="免费道具" help="自动领取免费道具" valuePropName="checked">
                <Switch checked={config.autoFreePurchase ?? false} onChange={(checked) => handleChange("autoFreePurchase", checked)} />
            </Form.Item>

            {/* 自动拉玉 */}
            <Form.Item label="自动拉玉" help="选择自动拉玉的策略">
                <Select
                    mode="multiple"
                    value={
                        config.xianyuActivityIDs?.length === Object.keys(activityDict).length
                            ? ["all"] // 显示全选
                            : config.xianyuActivityIDs // 多选模式
                    }
                    onChange={(value) => {
                        // 如果选择了全选
                        if (value.includes("all") && value.length === 1) {
                            handleChange(
                                "xianyuActivityIDs",
                                Object.keys(activityDict).map((key) => parseInt(key)) // 全选模式
                            );
                            return;
                        }

                        // 如果用户在全选的基础上取消部分选项，移除 "all"
                        const filteredValues = value.filter((v) => v !== "all").map(Number);

                        // 更新状态为多选模式或清空
                        handleChange("xianyuActivityIDs", filteredValues);
                    }}
                    style={{ width: "100%" }}
                    placeholder="请选择策略"
                >
                    <Select.Option key="all" value="all">
                        全选
                    </Select.Option>
                    {Object.entries(activityDict).map(([key, label]) => (
                        <Select.Option key={key} value={parseInt(key)}>
                            {label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <h4>炼器大会</h4>
            {/* 自动炼器 */}
            <Form.Item label="自动炼器" valuePropName="checked">
                <Switch checked={config.castSword?.enabled ?? false} onChange={(checked) => handleSubChange("castSword", "enabled", checked)} />
            </Form.Item>

            {/* 使用道具 */}
            <Form.Item label="使用道具" valuePropName="checked">
                <Switch checked={config.castSword?.useItem ?? false} onChange={(checked) => handleSubChange("castSword", "useItem", checked)} />
            </Form.Item>

            {/* 显示结果 */}
            <Form.Item label="显示结果" valuePropName="checked">
                <Switch checked={config.castSword?.showResult ?? false} onChange={(checked) => handleSubChange("castSword", "showResult", checked)} />
            </Form.Item>

            <h4>蛮荒妖域</h4>
            {/* 禁看广告 */}
            <Form.Item label="禁看广告" valuePropName="checked">
                <Switch checked={config.manHuang?.disableAds ?? false} onChange={(checked) => handleSubChange("manHuang", "disableAds", checked)} />
            </Form.Item>

            {/* 自动蛮荒 */}
            <Form.Item label="自动蛮荒" valuePropName="checked">
                <Switch checked={config.manHuang?.enabled ?? false} onChange={(checked) => handleSubChange("manHuang", "enabled", checked)} />
            </Form.Item>

            {/* 使用道具 */}
            <Form.Item label="使用道具" valuePropName="checked">
                <Switch checked={config.manHuang?.useItem ?? false} onChange={(checked) => handleSubChange("manHuang", "useItem", checked)} />
            </Form.Item>

            <h4>仙域商途</h4>
            {/* 自动跑船 */}
            <Form.Item label="自动跑船" valuePropName="checked">
                <Switch checked={config.skyTrade?.enabled ?? false} onChange={(checked) => handleSubChange("skyTrade", "enabled", checked)} />
            </Form.Item>

            {/* 使用道具 */}
            <Form.Item label="使用道具" valuePropName="checked">
                <Switch checked={config.skyTrade?.useItem ?? false} onChange={(checked) => handleSubChange("skyTrade", "useItem", checked)} />
            </Form.Item>
        </Form>
    );
};

export default ActivityTab;

import React from "react";
import "./style.css";
import { LoginForm } from "./LoginForm";

export const Login = () => {
    return (
        <div>
            <div className="background"></div>
            <div className="glass-overlay"></div>
            <div className="login-page">
                <LoginForm />
            </div>
        </div>
    );
};

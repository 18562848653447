import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "@/utils/auth";
import { storage } from "@/utils/storage";
import { ApiLogin, ApiRegister, ApiGetQuota, ApiGetSubUsers, ApiChangePassword } from "@/api/useUser";
import { StatusStream } from "@/api/useContainer";
import { parseJwt } from "@/utils/cipher";

// 用户登录
export const Login = createAsyncThunk("user/login", async ({ username, password }, { rejectWithValue }) => {
    try {
        const data = await ApiLogin(username, password);
        auth.set(data.token);
        storage.set("quota", data.quota);
        storage.set("id", data.id);
        return { token: data.token, quota: data.quota, status: data.status, id: data.id };
    } catch (error) {
        return rejectWithValue(error.message || "Login failed");
    }
});

// 用户注册
export const Register = createAsyncThunk("user/register", async ({ username, password }, { rejectWithValue }) => {
    try {
        const data = await ApiRegister(username, password);
        return data; // 返回注册成功的数据
    } catch (error) {
        return rejectWithValue(error.message || "Register failed");
    }
});

// 获取用户配额
export const GetQuota = createAsyncThunk("user/getQuota", async (_, { rejectWithValue }) => {
    const id = storage.get("id");
    if (!id) return rejectWithValue("No id found");
    try {
        const data = await ApiGetQuota(id);
        storage.set("quota", data);
        return { quota: data };
    } catch (error) {
        return rejectWithValue(error.message || "Failed to fetch quota");
    }
});

// 获取子账户列表
export const GetSubUsers = createAsyncThunk("user/getSubUsers", async (_, { rejectWithValue }) => {
    const id = storage.get("id");
    if (!id) return rejectWithValue("No id found");
    try {
        const data = await ApiGetSubUsers(id);
        return data;
    } catch (error) {
        return rejectWithValue(error.message || "Failed to fetch sub users");
    }
});

// 修改密码
export const ChangePassword = createAsyncThunk("user/changePassword", async ({ old_password, password }, { rejectWithValue }) => {
    const id = storage.get("id");
    if (!id) return rejectWithValue("No id found");
    try {
        await ApiChangePassword(id, old_password, password);
        return { success: true };
    } catch (error) {
        return rejectWithValue(error.message || "Failed to change password");
    }
});

// 用户登出
export const Logout = createAsyncThunk("user/logout", async (_, { dispatch }) => {
    try {
        auth.delete();
        storage.delete("quota");
        storage.delete("id");
        dispatch(resetState());
        window.location.href = "/login"; // 跳转登录页
    } catch (error) {
        console.error("Logout failed: ", error);
        throw error;
    }
});

// subscribeToContainerStatus
export const subscribeToContainerStatus = (dispatch) => {
    const id = storage.get("id");
    if (!id) {
        console.error("用户 ID 未找到");
        return;
    }

    const handleData = (data) => {
        if (JSON.parse(data) === null) return;

        const parsedData = JSON.parse(data);

        const items = Array.isArray(parsedData) ? parsedData : [parsedData];

        items.forEach((item) => {
            const isValid = item?.id && typeof item.status === "string";
            if (isValid) {
                console.log("接收到状态更新:", item);
                dispatch(updateStatus(item));
            } else {
                console.warn("无效的数据格式:", item);
            }
        });
    };

    const handleError = (error) => {
        console.error("状态流错误", error);
    };

    return StatusStream(id, handleData, handleError);
};

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            token: auth.get() || null,
            quota: storage.get("quota") ?? 0,
            id: storage.get("id") ?? null,
            sub_users: [],
        },
        is_authenticated: !!auth.get(),
        is_admin: (() => {
            const token = auth.get();
            if (!token) return false;

            try {
                const decoded = parseJwt(token);
                return decoded.is_admin ?? false;
            } catch (error) {
                console.error("Token 解码失败:", error);
                return false;
            }
        })(),
        error: null,
    },
    reducers: {
        resetState: (state) => {
            state.user = { token: null, quota: null, id: null };
            state.is_authenticated = false;
            state.is_admin = false;
        },
        updateSubUsers: (state, action) => {
            state.user.sub_users.push(action.payload); // 添加新子账户
        },
        removeSubUser: (state, action) => {
            state.user.sub_users = state.user.sub_users.filter((user) => user.id !== action.payload); // 删除子账户
        },
        updateStatus: (state, action) => {
            const { id, status } = action.payload;
            const subUserIndex = state.user.sub_users.findIndex((user) => user.id === id);
            if (subUserIndex !== -1) {
                state.user.sub_users[subUserIndex] = {
                    ...state.user.sub_users[subUserIndex],
                    status,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // 登录
            .addCase(Login.fulfilled, (state, action) => {
                const { token, quota, status, id } = action.payload;
                state.user.token = token;
                state.user.quota = quota;
                state.user.id = id;
                state.is_authenticated = true;
                state.is_admin = status === 2 ?? false;
            })
            // 获取配额
            .addCase(GetQuota.fulfilled, (state, action) => {
                state.user.quota = action.payload.quota;
            })
            // 获取子账户列表
            .addCase(GetSubUsers.fulfilled, (state, action) => {
                const data = action.payload;
                state.user.sub_users = data ?? [];
            });
    },
});

export const { resetState, updateSubUsers, removeSubUser, updateStatus } = userSlice.actions;
export default userSlice.reducer;
export const selectQuota = (state) => state.user.user.quota;

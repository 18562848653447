import React from "react";
import { Switch, InputNumber, Radio } from "antd";
import { Form } from "antd-mobile";
import { useDispatch } from "react-redux";
import { updateSetting } from "@/store/modules/setting";
import { avatarOptions } from "../constants";

const UnionTab = ({ setting }) => {
    const { union: config } = setting;
    const category = "union";
    const dispatch = useDispatch();

    const handleChange = (key, value) => {
        dispatch(updateSetting({ category, key, value }));
    };

    const handleSubChange = (field, subField, value) => {
        const updatedField = { ...config[field], [subField]: value };
        dispatch(updateSetting({ category, key: field, value: updatedField }));
    };

    const renderSeparation = (label, key, helpText) => (
        <Form.Item label={label} help={helpText}>
            <Radio.Group value={config.boss?.[key] || 0} onChange={(e) => handleSubChange("boss", key, e.target.value)}>
                {avatarOptions.map((option) => (
                    <Radio.Button key={option.value} value={option.value}>
                        {option.label}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </Form.Item>
    );

    return (
        <Form layout="horizontal">
            <h4>妖邪挑战</h4>
            <Form.Item label="开启功能" valuePropName="checked">
                <Switch checked={config.boss?.enabled ?? false} onChange={(checked) => handleSubChange("boss", "enabled", checked)} />
            </Form.Item>
            <Form.Item label="布阵时间" help="布阵时间, 默认为0点">
                <InputNumber min={0} max={23} value={config.boss?.scheduleHour ?? 0} onChange={(value) => handleSubChange("boss", "scheduleHour", value)} />
            </Form.Item>
            {setting.basic.hasSeparation && renderSeparation("妖邪分身", "index", "妖邪挑战使用的分身")}

            <h4>砍价设置</h4>
            <Form.Item label="人数偏移" help="达到 ( 总人数-偏移值 ) 就进行购买">
                <InputNumber min={0} value={config.bargain?.num ?? 0} onChange={(value) => handleSubChange("bargain", "num", value)} />
            </Form.Item>
            <Form.Item label="价格偏移" help="低于此价格就就行购买, 默认为0">
                <InputNumber value={config.bargain?.price ?? 0} onChange={(value) => handleSubChange("bargain", "price", value)} />
            </Form.Item>

            <h4>其他设置</h4>
            {/* 自动镇魔 */}
            <Form.Item label="自动镇魔" valuePropName="checked" help="是否开启自动镇魔功能">
                <Switch checked={config.suppressDemon ?? false} onChange={(checked) => handleChange("suppressDemon", checked)} />
            </Form.Item>

            {/* 妖盟对决 */}
            <Form.Item label="妖盟对决" valuePropName="checked" help="是否开启自动妖盟对决功能">
                <Switch checked={config.duel ?? false} onChange={(checked) => handleChange("duel", checked)} />
            </Form.Item>
        </Form>
    );
};

export default UnionTab;

import React, { useState } from "react";
import { Switch, Select, Radio, Dropdown, Input } from "antd";
import { Form } from "antd-mobile";
import { useDispatch } from "react-redux";
import { updateSetting } from "@/store/modules/setting";
import { wishPetDict, avatarOptions, timeUnits } from "../constants";
import { ChopTreeSetting } from "./chopTreeSetting";
import { TalentSetting } from "./talentSetting";

const BasicTab = ({ setting }) => {
    const { basic: config } = setting;
    const category = "basic";
    const dispatch = useDispatch();

    const [timeUnit, setTimeUnit] = useState(60000); // 默认单位为 "分"
    const reconnectIntervalInMs = config.reconnectInterval || 300000; // 默认重连间隔为 5 分钟
    const [inputValue, setInputValue] = useState("");

    // 直接计算派生值 displayInterval
    const displayInterval = inputValue !== "" ? inputValue : (reconnectIntervalInMs / timeUnit).toFixed(2);

    // 处理时间单位更改
    const handleTimeUnitChange = (newTimeUnit) => {
        setTimeUnit(newTimeUnit);
        setInputValue(""); // 清除用户输入值，以显示新的单位下的值
    };

    // 输入框失去焦点时更新状态
    const handleInputBlur = () => {
        if (inputValue !== "") {
            const updatedReconnectInterval = parseFloat(inputValue) * timeUnit;
            dispatch(updateSetting({ category, key: "reconnectInterval", value: updatedReconnectInterval }));
        }
    };

    const menuItems = timeUnits.map((unit) => ({
        key: unit.value,
        label: unit.label,
    }));

    const handleChange = (key, value) => {
        dispatch(updateSetting({ category, key, value }));
    };

    const createSubHandler = (field) => (key, value) => {
        const updatedField = { ...config[field], [key]: value };
        dispatch(updateSetting({ category, key: field, value: updatedField }));
    };

    const handleTree = createSubHandler("chopTree");
    const handleTalent = createSubHandler("talent");
    const handlePet = createSubHandler("pet");

    const TODO = <span style={{ fontStyle: "italic", color: "#888" }}>TODO</span>;
    const renderSeparation = (label, key, helpText) => (
        <Form.Item label={label} help={helpText}>
            <Radio.Group value={config[key] || 0} onChange={(e) => handleChange(key, e.target.value)}>
                {avatarOptions.map((option) => (
                    <Radio.Button key={option.value} value={option.value}>
                        {option.label}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </Form.Item>
    );

    return (
        <Form layout="horizontal">
            <h4>基础设置</h4>

            {/* 重连间隔设置 */}
            <Form.Item label="重连间隔" help="自动顶号间隔，建议设置为 5 分钟">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Input
                        type="number"
                        value={displayInterval}
                        onChange={(e) => setInputValue(e.target.value)}
                        onBlur={handleInputBlur}
                        style={{ width: "60%" }}
                    />
                    <Dropdown
                        menu={{
                            items: menuItems,
                            onClick: ({ key }) => handleTimeUnitChange(parseInt(key, 10)),
                        }}
                        trigger={["click"]}
                    >
                        <span className="time-selector">{timeUnits.find((unit) => unit.value === timeUnit)?.label || "分"}</span>
                    </Dropdown>
                </div>
            </Form.Item>

            {/* 自动任务 */}
            <Form.Item label="拥有分身" valuePropName="checked" help="重要配置 会影响后续砍树灵脉 切换分身的行为">
                <Switch checked={config.hasSeparation ?? false} onChange={(checked) => handleChange("hasSeparation", checked)} />
            </Form.Item>

            {/* 启动锤炼仙树 */}
            <Form.Item label="启动砍树" valuePropName="checked">
                <Switch checked={config.chopTree.enabled ?? false} onChange={(checked) => handleTree("enabled", checked)} />
            </Form.Item>

            {/* 仅当启用锤炼仙树时显示 */}
            {config.chopTree.enabled && <ChopTreeSetting hasSeparation={config.hasSeparation} setting={config.chopTree} onChange={handleTree} />}

            {/* 启动激发灵脉 */}
            <Form.Item label="激发灵脉" valuePropName="checked">
                <Switch checked={config.talent.enabled ?? false} onChange={(checked) => handleTalent("enabled", checked)} />
            </Form.Item>

            {/* 仅当启动激发灵脉时显示 */}
            {config.talent.enabled && <TalentSetting hasSeparation={config.hasSeparation} setting={config.talent} onChange={handleTalent} />}

            {/* 自动任务 */}
            <Form.Item label="自动任务" valuePropName="checked">
                <Switch checked={config.autoTask ?? false} onChange={(checked) => handleChange("autoTask", checked)} />
            </Form.Item>

            {/* 自动渡劫 */}
            <Form.Item label="自动渡劫" valuePropName="checked">
                <Switch checked={config.autoDuJie ?? false} onChange={(checked) => handleChange("autoDuJie", checked)} />
            </Form.Item>

            {/* 自动心魔劫 */}
            <Form.Item label="自动心魔劫" valuePropName="checked">
                {TODO}
            </Form.Item>

            {/* 座驾升级 */}
            <Form.Item label="座驾升级" valuePropName="checked">
                <Switch checked={config.autoUpgradeCloud1 ?? false} onChange={(checked) => handleChange("autoUpgradeCloud1", checked)} />
            </Form.Item>

            <Form.Item label="座驾注灵" valuePropName="checked">
                <Switch checked={config.autoUpgradeCloud2 ?? false} onChange={(checked) => handleChange("autoUpgradeCloud2", checked)} />
            </Form.Item>

            {/* 仅当hasSeparation为 true 时显示 */}
            {config.hasSeparation && renderSeparation("默认分身", "defaultIndex", "任务完成后切换的分身")}

            <h4>灵兽设置</h4>
            {/* 视频广告 */}
            <Form.Item label="开启功能" help="是否使用免费的4次刷新" valuePropName="checked">
                <Switch checked={config.pet.enabled ?? false} onChange={(checked) => handlePet("enabled", checked)} />
            </Form.Item>

            {/* 仅当启用视频广告时显示 */}
            {config.pet.enabled && (
                <>
                    {/* 期望灵兽 */}
                    <Form.Item label="期望灵兽">
                        <Select mode="multiple" value={config.pet.wishPets ?? []} onChange={(value) => handlePet("wishPets", value)} style={{ width: "100%" }}>
                            {Object.keys(wishPetDict).map((key) => (
                                <Select key={key} value={parseInt(key)}>
                                    {wishPetDict[key]}
                                </Select>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* 自动捕获 */}
                    <Form.Item label="自动捕获" valuePropName="checked" help="刷到心仪灵兽后是否自动捕捉">
                        <Switch checked={config.pet.autoCapture ?? false} onChange={(checked) => handlePet("autoCapture", checked)} />
                    </Form.Item>
                </>
            )}
        </Form>
    );
};

export default BasicTab;

import { useDispatch } from "react-redux";
import { Register } from "@/store/modules/user";
import { Form, Button, Input, message } from "antd";

export const RegisterForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    

    const onSubmit = async (values) => {
        try {
            await dispatch(Register({ username: values.username, password: values.password })).unwrap();
            message.success("注册成功!");
            handleClose();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="RegisterForm">
            <div className="form">
                <Form layout="vertical" onFinish={onSubmit} name="register" className="register-form">
                    <Form.Item name="username" label="账号" rules={[{ required: true, message: "请输入账号" }]}>
                        <Input placeholder="账号" />
                    </Form.Item>
                    <Form.Item name="password" label="密码" rules={[{ required: true, message: "请输入密码" }]}>
                        <Input type="password" placeholder="密码" />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "right" }}>
                        <Button htmlType="submit" className="register-button">
                            注册
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

import { ContainerApiDefinition } from "@/domains/container";
import { createRequest } from "@/utils/request";
import env from "@/config";

export const Run = (id) => createRequest(ContainerApiDefinition.run)({ data: { id } });

export const Stop = (id) => createRequest(ContainerApiDefinition.stop)({ data: { id } });

// WebSocket
export const LogStream = (id, onMessage) => {
    const apiUrl = env.API_URL.startsWith("http://") || env.API_URL.startsWith("https://") ? env.API_URL : `${window.location.origin}${env.API_URL}`;

    const protocol = apiUrl.startsWith("https://") ? "wss" : "ws";

    const wsUrl = `${protocol}://${new URL(apiUrl).host}/api/container/log-stream?id=${id}`;

    const socket = new WebSocket(wsUrl);

    // WebSocket 连接保持心跳
    let pingInterval;

    const cleanUp = () => {
        if (pingInterval) clearInterval(pingInterval);
    };

    socket.onopen = () => {
        console.log("WebSocket connection established.");
        pingInterval = setInterval(() => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send("");
            }
        }, 5000);
    };

    socket.onmessage = (event) => {
        if (onMessage) onMessage(extractAnsiCodes(event.data.trim()));
    };

    socket.onclose = cleanUp;
    socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        cleanUp();
    };

    return socket;
};

// ANSI 颜色码处理函数
export const extractAnsiCodes = (log) => {
    const regex = /\x1b\[([0-9;]+)m([^\x1b]+)\x1b\[[0-9;]*m/g;
    return log.replace(regex, (match, colorCode, text) => {
        const colors = {
            31: "red",
            32: "green",
            33: "yellow",
            34: "blue",
            35: "magenta",
            36: "cyan",
        };
        const color = colors[colorCode] || "white";
        return `<span style="color:${color}; font-weight:bold;">${text}</span>`;
    });
};

// SSE 订阅事件
export const StatusStream = (id, onStatus, onError) => {
    const apiUrl = env.API_URL.startsWith("http://") || env.API_URL.startsWith("https://") ? env.API_URL : `${window.location.origin}${env.API_URL}`;
    const eventSource = new EventSource(`${new URL(apiUrl).origin}/api/container/status-stream?id=${id}`);

    eventSource.addEventListener("status", (event) => {
        if (onStatus) onStatus(event.data);
    });

    eventSource.onerror = (event) => {
        if (onError) onError(event);
        eventSource.close();
    };

    return eventSource;
};

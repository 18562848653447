import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Steps, message, Select } from "antd";
import { GetServers } from "@/store/modules/subUser";
import { useDispatch } from "react-redux";

const { Step } = Steps;

const AddAccount = ({ isModalOpen, onClose, onAddAccount }) => {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(0); // 当前步骤
    const [serverOptions, setServerOptions] = useState([]);
    const [form] = Form.useForm();
    const defaultAccountData = {
        username: "",
        password: "",
        server_id: 0,
        server_name: "",
        token: "",
        uid: "",
    };
    const [accountData, setAccountData] = useState(defaultAccountData);

    // 重置状态和表单
    useEffect(() => {
        if (isModalOpen) {
            form.resetFields();
            setAccountData(defaultAccountData);
            setServerOptions([]);
            setCurrentStep(0);
        }
    }, [isModalOpen, form]);

    const handleNext = async () => {
        if (currentStep === 0) {
            await handleLoginStep();
        } else if (currentStep === 1) {
            await handleSubmit();
        }
    };

    const handleLoginStep = async () => {
        const values = await form.validateFields();
        const { username, password } = values;

        try {
            const response = await dispatch(GetServers({ username, password })).unwrap();

            const servers = response.server_list.servers.map((server) => ({
                value: server.serverId,
                label: server.serverName,
            }));
            setServerOptions(servers);

            setAccountData((prev) => ({
                ...prev,
                username: username,
                password: password,
                token: response.token,
                uid: response.uid,
            }));
            setCurrentStep(1);
        } catch (error) {
            console.log(`${error}`);
        }
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const selectedServer = serverOptions.find((server) => server.value === values.server);

            if (!selectedServer) {
                message.error("服务器不可用，请返回登录重试！");
                setCurrentStep(0);
                return;
            }

            const account = {
                ...accountData,
                server_id: values.server,
                server_name: selectedServer.label,
            };
            await onAddAccount(account);
        } catch (error) {
            console.error(error);
        } finally {
            handleClose();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const steps = [
        {
            title: "登录",
            content: (
                <>
                    <Form.Item name="username" label="账户" rules={[{ required: true, message: "请输入账户" }]}>
                        <Input placeholder="请输入账户" />
                    </Form.Item>
                    <Form.Item name="password" label="密码" rules={[{ required: true, message: "请输入密码" }]}>
                        <Input.Password placeholder="请输入密码" />
                    </Form.Item>
                </>
            ),
        },
        {
            title: "选择服务器",
            content: (
                <Form.Item name="server" label="服务器" rules={[{ required: true, message: "请选择服务器" }]}>
                    <Select placeholder="请选择服务器">
                        {serverOptions.map((server) => (
                            <Select.Option key={server.value} value={server.value}>
                                {server.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ),
        },
    ];

    return (
        <Modal title="添加账号" open={isModalOpen} onCancel={handleClose} footer={null} destroyOnClose centered maskClosable={false}>
            <Steps current={currentStep} size="small" style={{ marginBottom: "24px" }}>
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <Form form={form} layout="vertical">
                {steps[currentStep].content}
                <Form.Item>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {currentStep > 0 && (
                            <Button onClick={() => setCurrentStep(currentStep - 1)} style={{ marginRight: "8px" }}>
                                上一步
                            </Button>
                        )}
                        <Button type="primary" onClick={handleNext}>
                            {currentStep === steps.length - 1 ? "完成" : "下一步"}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddAccount;

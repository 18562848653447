import { AdminApiDefinition } from "@/domains/admin";
import { createRequest } from "@/utils/request";

export const ApiGetUsers = () => createRequest(AdminApiDefinition.getUsers)({});
export const ApiGetSubUsers = () => createRequest(AdminApiDefinition.getSubUsers)({});

export const ApiUserDelete = (user_id) => createRequest(AdminApiDefinition.deleteUser)({ url: { id: user_id } });
export const ApiUpdateQuota = (user_id, quota) => createRequest(AdminApiDefinition.updateUserQuota)({ url: { id: user_id }, data: { quota } });
export const ApiUpdateStatus = (user_id, status) => createRequest(AdminApiDefinition.updateUserStatus)({ url: { id: user_id }, data: { status } });
export const ApiUpdatePassword = (user_id, password) => createRequest(AdminApiDefinition.updateUserPassword)({ url: { id: user_id }, data: { old_password: "123456", password: password } });


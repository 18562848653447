import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiGetServers, ApiBind, ApiDeleteSubUser, ApiChangeSubUserPassword } from "@/api/useSubUser";
import { storage } from "@/utils/storage";
import { updateSubUsers, removeSubUser } from "@/store/modules/user";

// 获取 37 服务器列表
export const GetServers = createAsyncThunk("subUser/getServers", async ({ username, password }, { rejectWithValue }) => {
    try {
        const response = await ApiGetServers(username, password);
        return response;
    } catch (error) {
        return rejectWithValue(error.message || "Failed to fetch server list");
    }
});

// 绑定子账户
export const Bind = createAsyncThunk("subUser/bind", async (payload, { dispatch, rejectWithValue }) => {
    try {
        const parent_id = storage.get("id");
        const response = await ApiBind(parent_id, payload.username, payload.password, payload.token, payload.server_name, payload.server_id, payload.uid);

        dispatch(updateSubUsers(response));
        return response.sub_user;
    } catch (error) {
        return rejectWithValue(error.message || "Failed to bind sub-user");
    }
});

// 删除子账户
export const DeleteSubUser = createAsyncThunk("subUser/delete", async ({sub_user_id}, { dispatch, rejectWithValue }) => {
    try {
        await ApiDeleteSubUser(sub_user_id);

        dispatch(removeSubUser(sub_user_id));
        return sub_user_id;
    } catch (error) {
        return rejectWithValue(error.message || "Failed to delete sub-user");
    }
});

// 修改子账户密码
export const ChangeSubUserPassword = createAsyncThunk("subUser/changePassword", async ({ sub_user_id, old_password, password }, { rejectWithValue }) => {
    try {
        await ApiChangeSubUserPassword(sub_user_id, old_password, password);
        return { sub_user_id };
    } catch (error) {
        return rejectWithValue(error.message || "Failed to change password");
    }
});

const subUserSlice = createSlice({
    name: "subUser",
    initialState: {
        isLoading: false,
        error: null,
    },
    reducers: {},
});

export default subUserSlice.reducer;

const config = {
    development: {
        API_URL: "http://localhost:8080/",
    },
    production: {
        API_URL: "",
    },
};

const env = process.env.NODE_ENV || "development";
const currentConfig = config[env]; // 根据当前环境获取对应的配置

export default currentConfig;

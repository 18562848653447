import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import env from "@/config";
import { Login } from "@/pages/Login";
import { Home } from "@/pages/Home";
import { Help } from "@/pages/Help";
import { Setting } from "@/pages/Setting";
import { Log } from "@/pages/Log";
import { Unauthorized } from "@/pages/403";
// admin
import { AdminHome } from "@/pages/AdminHome";
import { AdminUsers } from "@/pages/AdminUsers";
import { AdminSubUsers } from "@/pages/AdminSubUsers";

const PrivateRoute = ({ children, adminOnly = false }) => {
    const { is_authenticated, is_admin } = useSelector((state) => state.user);

    // 测试专用
    if (env.IS_DEV) return children;

    if (!is_authenticated) {
        return <Navigate to="/login" replace />;
    }

    if (adminOnly && !is_admin) {
        return <Navigate to="/unauthorized" replace />;
    }

    return children;
};

const userRoutes = [
    { path: "/home", element: <Home /> },
    { path: "/help", element: <Help /> },
    { path: "/setting", element: <Setting /> },
    { path: "/log", element: <Log /> },
];

const adminRoutes = [
    { path: "/admin/home", element: <AdminHome /> },
    { path: "/admin/home/users", element: <AdminUsers /> },
    { path: "/admin/home/sub-users", element: <AdminSubUsers /> },
];

const AppRouter = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    {/* 公共路由 */}
                    <Route path="/login" element={<Login />} />

                    {/* 受保护路由 */}
                    {userRoutes.map(({ path, element }) => (
                        <Route key={path} path={path} element={<PrivateRoute>{element}</PrivateRoute>} />
                    ))}

                    {/* Admin路由 */}
                    {adminRoutes.map(({ path, element }) => (
                        <Route key={path} path={path} element={<PrivateRoute adminOnly={true}>{element}</PrivateRoute>} />
                    ))}

                    {/* 无权限页面 */}
                    <Route path="/unauthorized" element={<Unauthorized />} />

                    {/* 未知路由重定向 */}
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default AppRouter;

import { UserApiDefinition } from "@/domains/user";
import { createRequest } from "@/utils/request";

export const ApiLogin = (username, password) => createRequest(UserApiDefinition.login)({ data: { username, password } });

export const ApiRegister = (username, password) => createRequest(UserApiDefinition.register)({ data: { username, password } });

export const ApiGetQuota = (id) => createRequest(UserApiDefinition.getQuota)({ url: { id } });

export const ApiGetSubUsers = (id) => createRequest(UserApiDefinition.getSubUsers)({ url: { id }});

export const ApiChangePassword = (id, old_password, password) =>
    createRequest(UserApiDefinition.changePassword)({ url: { id }, data: { old_password, password } });

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

const setFavicon = () => {
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
        favicon.href = process.env.PUBLIC_URL + "/assets/logo.svg";
    }
};

setFavicon();
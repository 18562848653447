import axios from "axios";
import { message, Modal } from "antd";
import env from "@/config";
import store from "@/store";
import { Logout } from "@/store/modules/user";

const request = axios.create({
    baseURL: env.API_URL,
    timeout: 15000,
});

request.interceptors.request.use(
    (config) => {
        const token = store.getState().user.user.token;
        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: token,
            };
        }
        return config;
    },
    (error) => {
        console.error("请求错误: ", error);
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    (response) => {
        const { code, msg, data } = response.data;

        if (code !== 200) {
            return Promise.reject({ code, msg });
        }
        
        return data;
    },
    (error) => {
        return Promise.reject(error?.response || error);
    }
);

const handleError = (response) => {
    let error_msg = "未知错误";

    if (response && typeof response === "object") {
        // 如果返回的是标准结构 { Msg, Code, Data }
        const { msg, code } = response;
        error_msg = msg || `接口返回错误 (Code: ${code})`;
    }

    // 针对 401 错误的特殊处理
    if (response?.status === 401) {
        message.error(error_msg);
        Modal.confirm({
            title: "登录过期",
            content: "您的登录状态已失效，请重新登录。",
            okText: "重新登录",
            cancelText: "取消",
            onOk: () => {
                store.dispatch(Logout());
            },
        });
    } else {
        message.error({
            content: error_msg,
            duration: 3,
            key: "global-error",
        });
    }
};

// 动态创建请求方法
export const createRequest = (apiDefinition) => {
    return async ({ params = {}, data = {}, url = {} }) => {
        try {
            const { method, url: urlTemplate } = apiDefinition;

            const endpoint = typeof urlTemplate === "function" ? urlTemplate(url) : urlTemplate;

            const config = {
                method: method.toLowerCase(),
                url: endpoint,
                ...(method.toLowerCase() === "get" ? { params } : { data }),
            };

            const response = await request(config);
            return response;
        } catch (error) {
            handleError(error);
            console.error(`接口 "${apiDefinition.description || "请求"}" 失败`, error);
            throw error;
        }
    };
};

import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./modules/user";
import subUserSlice from "./modules/subUser";
import settingSlice from "./modules/setting";
import adminSlice from "./modules/admin";

const store = configureStore({
    reducer: {
        user: userSlice,
        subUser: subUserSlice,
        setting: settingSlice,
        admin: adminSlice,
    },
});

export default store;

import React from "react";
import "./style.css";
import { UserTable } from "./UserTable";
import NavBar from "@/components/Navbar";


export const AdminUsers = () => {
    return (
        <div className="admin-users-container">
            <NavBar />
            <div className="admin-users-table">
                <UserTable />
            </div>
        </div>
    );
};

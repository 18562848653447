export const ContainerApiDefinition = {
    logStream: {
        method: "websocket",
        url: ({ id }) => `/api/container/log-stream?id=${id}`,
        description: "获取容器日志流(WebSocket)",
    },
    statusStream: {
        method: "sse",
        url: ({ id }) => `/api/container/status-stream?id=${id}`,
        description: "获取容器状态流(SSE)",
    },
    run: {
        method: "post",
        url: "/api/container/run",
        description: "启动",
    },
    stop: {
        method: "post",
        url: "/api/container/stop",
        description: "停止",
    },
};

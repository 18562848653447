export const storage = {
    set(key, value) {
        const stringValue = JSON.stringify(value);
        localStorage.setItem(key, stringValue);
    },

    get(key) {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    },

    delete(key) {
        localStorage.removeItem(key);
    },

    clear() {
        localStorage.clear();
    },
};

import React from "react";
import { Form } from "antd-mobile";
import { InputNumber, Select, Switch } from "antd";
import { talentAttributeDict, talentSkillDict, talentQualityDict } from "../constants";

export const TalentSetting = ({ hasSeparation, setting, onChange }) => {
    const handleAttributeChange = (index, type, value, isMain = false) => {
        if (isMain) {
            const updatedMain = { ...setting.main, [type]: value };
            onChange("main", updatedMain);
        } else {
            const updatedConditions = [...setting.separation];
            updatedConditions[index] = {
                ...updatedConditions[index],
                [type]: value,
            };
            onChange("separation", updatedConditions);
        }
    };

    const getOptions = (dict) =>
        Object.keys(dict).map((key) => ({
            label: dict[key],
            value: parseInt(key),
        }));

    const renderAttributeSelection = (label, index, type, isMain = false) => {
        const source = isMain ? setting.main : setting.separation[index] || { attribute: [], skillId: [] }; // 提供默认值

        const current = source[type] || [];

        return (
            <Form.Item label={label}>
                <Select
                    mode="multiple"
                    value={current}
                    options={getOptions(type === "attribute" ? talentAttributeDict : talentSkillDict)}
                    onChange={(value) => handleAttributeChange(index, type, value, isMain)}
                    style={{ width: "100%" }}
                    placeholder="请选择"
                />
            </Form.Item>
        );
    };

    return (
        <Form layout="horizontal">
            {/* 显示结果 */}
            <Form.Item label="显示结果">
                <Switch checked={setting.showResult} onChange={(val) => onChange("showResult", val)} />
            </Form.Item>

            {/* 停止条件 */}
            <Form.Header>停止条件</Form.Header>
            <Form.Item label="灵草数量" help="灵草数量 < 该数 停止激发">
                <InputNumber
                    min={1}
                    value={setting.stop.stopNum}
                    style={{ width: "100%" }}
                    onChange={(value) => onChange("stop", { ...setting.stop, stopNum: value })}
                />
            </Form.Item>

            <Form.Item label="激发次数" help="-1为无穷大，表示一直激发">
                <InputNumber
                    min={-1}
                    value={setting.stop.doNum}
                    style={{ width: "100%" }}
                    formatter={(value) => (String(value) === "-1" ? "无穷大" : value)}
                    parser={(value) => (value === "无穷大" ? -1 : parseInt(value || 0, 10))}
                    onChange={(value) => onChange("stop", { ...setting.stop, doNum: value })}
                />
            </Form.Item>

            {/* 分离设置 */}
            <Form.Header>基础设置</Form.Header>
            <Form.Item label="最低品质" help="最低能接受的品质">
                <Select
                    value={setting.quality}
                    options={getOptions(talentQualityDict)}
                    onChange={(value) => onChange("quality", value)}
                    style={{ width: "100%" }}
                />
            </Form.Item>

            {/* 属性设置或分身设置 */}
            {hasSeparation ? (
                ["元体", "阳神", "阴身"].map((name, index) => (
                    <>
                        <Form.Header>{name}</Form.Header>
                        {renderAttributeSelection("主属性", index, "attribute")}
                        {renderAttributeSelection("技能", index, "skillId")}
                    </>
                ))
            ) : (
                <>
                    <Form.Header>属性设置</Form.Header>
                    {renderAttributeSelection("主属性", 0, "attribute", true)}
                    {renderAttributeSelection("技能", 0, "skillId", true)}
                </>
            )}
        </Form>
    );
};

import React, { useEffect, useState, useRef } from "react";
import { Empty, message, FloatButton } from "antd";
import { useSearchParams } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";
import NavBar from "@/components/Navbar";
import { LogStream } from "@/api/useContainer";
import "./style.css";

export const Log = () => {
    const [searchParams] = useSearchParams();

    const id = searchParams.get("id");

    const [logs, setLogs] = useState([]);
    const logsEndRef = useRef(null);
    const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);

    let socketInstance = null;
    let isSocketClosing = false;
    
    useEffect(() => {
        if (!id) {
            console.error("No id provided");
            return;
        }
    
        if (isSocketClosing) return;
    
        const initializeSocket = () => {
            if (socketInstance) {
                isSocketClosing = true;
                socketInstance.close();
                console.log("Closing previous WebSocket connection.");
            }
    
            socketInstance = LogStream(id, (newLog) => {
                setLogs((prevLogs) => [...prevLogs, newLog]);
            });
    
            isSocketClosing = false;
        };
    
        initializeSocket();
    
        return () => {
            if (socketInstance) {
                socketInstance.close();
                socketInstance = null;
            }
        };
    }, [id]);      

    // 自动滚动到最新日志
    useEffect(() => {
        if (isAutoScrollEnabled) {
            logsEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [logs, isAutoScrollEnabled]);

    const toggleAutoScroll = () => {
        const newState = !isAutoScrollEnabled;
        setIsAutoScrollEnabled(newState);
        message.success(newState ? "自动滚动开启" : "自动滚动关闭");
    };

    return (
        <div>
            <NavBar />
            {!id ? (
                <Empty description="未提供 ID" className="log-empty" />
            ) : (
                <div className="log-content">
                    {logs.length === 0 ? (
                        <Empty description="暂无数据" className="log-empty" />
                    ) : (
                        <div className="log-card">
                            {logs.map((log, index) => (
                                <div key={index} className="log-entry" dangerouslySetInnerHTML={{ __html: log }} />
                            ))}
                            <div ref={logsEndRef} />
                        </div>
                    )}
                    <FloatButton icon={<ReloadOutlined />} type="primary" className="log-float-button" onClick={toggleAutoScroll} />
                </div>
            )}
        </div>
    );
};

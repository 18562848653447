export const UserApiDefinition = {
    login: {
        method: "post",
        url: "/api/auth/login",
        description: "登录接口",
    },
    register: {
        method: "post",
        url: "/api/auth/register",
        description: "注册接口",
    },
    getQuota: {
        method: "get",
        url: (urlParams) => `/api/user/${urlParams.id}/quota`,
        description: "获取用户配额",
    },
    getSubUsers: {
        method: "get",
        url: (urlParams) => `/api/user/${urlParams.id}/sub-users`,
        description: "获取子账户列表",
    },
    changePassword: {
        method: "put",
        url: (urlParams) => `/api/user/${urlParams.id}/password`,
        description: "修改密码",
    },
};

import React, { useState, useEffect } from "react";
import { Button, Form, Input, Checkbox, Modal, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Login } from "@/store/modules/user";
import { setCookie, getCookie, deleteCookie } from "@/utils/cookie";
import { encrypt, decrypt } from "@/utils/cipher";
import logo from "@/assets/logo.svg";
import { RegisterForm } from "../RegisterForm";

export const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();


    useEffect(() => {
        const savedUsername = getCookie("remembered_username");
        const savedPassword = getCookie("remembered_password");
        if (savedUsername && savedPassword) {
            form.setFieldsValue({
                username: savedUsername,
                password: decrypt(savedPassword), // 解密密码
                remember: true,
            });
        }
    }, [form]);

    const onSubmit = async (values) => {
        try {
            const { username, password, remember } = values;
            await dispatch(Login({ username, password })).unwrap();

            if (remember) {
                setCookie("remembered_username", username, 7);
                setCookie("remembered_password", encrypt(password), 7);
            } else {
                deleteCookie("remembered_username");
                deleteCookie("remembered_password");
            }
            message.success("登录成功！");
            navigate("/home");
        } catch (e) {
            console.error(e);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="login-container">
            <div className="logo-section">
                <img src={logo} alt="熊猫助手 Logo" className="login-logo" />
            </div>
            <div className="login-form-wrapper">
                <Form form={form} onFinish={onSubmit} className="login-form">
                    <Form.Item name="username" rules={[{ required: true, message: "请输入账号" }]}>
                        <Input placeholder="账号" className="login-input" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: "请输入密码" }]}>
                        <Input.Password placeholder="密码" className="login-input" />
                    </Form.Item>
                    <div className="login-options">
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>记住我</Checkbox>
                        </Form.Item>
                    </div>
                    <Form.Item>
                        <Button type="primary" danger htmlType="submit" className="login-button">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
                <div className="register-link">
                    <span onClick={showModal} style={{ cursor: "pointer", color: "#1890ff" }}>
                        注册新用户？
                    </span>
                </div>
            </div>

            {/* 注册 Modal */}
            <Modal title="注册新用户" open={isModalOpen} onCancel={handleCancel} footer={null} destroyOnClose centered maskClosable={false}>
                <RegisterForm key="regist" handleClose={handleCancel} />
            </Modal>
        </div>
    );
};

import React, { useState } from "react";
import { Dropdown, Button, Typography, Modal, Form, Input, message } from "antd";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { updateStatus } from "@/store/modules/user";
import { ChangeSubUserPassword, DeleteSubUser } from "@/store/modules/subUser";
import { Run, Stop } from "@/api/useContainer";
import { useNavigate } from "react-router-dom";
import "./style.css";

const AccountCard = ({ id, is_admin }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const subUser = useSelector((state) => {
        return is_admin ? state.admin.sub_users.find((user) => user.id === id) || {} : state.user.user.sub_users.find((user) => user.id === id) || {};
    });

    // 如果数据不存在，设置默认值
    const {
        id: sub_user_id = "",
        nickname = "未知昵称",
        server_name = "未知服务器",
        status = "unknown",
        username: subuser_username = "",
        password: subuser_password = "",
    } = subUser;
    // TODO 解决status更新不是实时的

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteCountdown, setDeleteCountdown] = useState(5);

    const handleCopy = (text) => {
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard
                .writeText(text)
                .then(() => message.success("复制成功"))
                .catch(() => copyUsingTextArea(text));
        } else {
            copyUsingTextArea(text);
        }
    };

    const copyUsingTextArea = (text) => {
        const textArea = document.createElement("textarea");
        Object.assign(textArea.style, { position: "fixed", opacity: "0", left: "-9999px" });
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        if (document.execCommand("copy")) {
            message.success("复制成功");
        } else {
            message.error("复制失败");
        }
        document.body.removeChild(textArea);
    };

    const handleChangePassword = async (values) => {
        try {
            const { old_password, password } = values;
            await dispatch(ChangeSubUserPassword({ sub_user_id: id, old_password, password })).unwrap();
            message.success("密码修改成功！");
            setIsModalOpen(false);
        } catch (error) {
            console.log(`修改密码失败: ${error}`);
        }
    };

    const handleDeleteAccount = async () => {
        try {
            await dispatch(DeleteSubUser({ sub_user_id: id })).unwrap();
            message.success("账户已删除");
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error(error);
            message.error(`账户删除失败: ${error}`);
        }
    };

    const showDeleteModalWithCountdown = () => {
        setDeleteCountdown(3);
        setIsDeleteModalOpen(true);
        const timer = setInterval(() => {
            setDeleteCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                }
                return prev - 1;
            });
        }, 1000);
    };

    const handleRun = async () => {
        try {
            message.success("正在启动");
            await Run(sub_user_id);
            message.success("启动成功！");
        } catch (error) {
            console.error("启动失败：", error);
        }
    };

    const handleStop = async () => {
        try {
            message.success("正在停止");
            // 伪造一个Terminating的状态
            dispatch(updateStatus({ id: sub_user_id, status: "Terminating" }));
            await Stop(sub_user_id);
            message.success("停止成功！");
        } catch (error) {
            console.error("停止失败：", error);
        }
    };

    const handleLog = () => {
        navigate(`/log?id=${sub_user_id}`);
    };

    const handleSetting = () => {
        navigate(`/setting?id=${sub_user_id}`);
    };

    const items = [
        { key: "change-password", label: "修改密码", onClick: () => setIsModalOpen(true) },
        { type: "divider" },
        { key: "delete-account", label: <span style={{ color: "red" }}>删除账户</span>, onClick: showDeleteModalWithCountdown },
    ];

    const getStatusColor = (status) => {
        switch (status) {
            case "create":
            case "start":
            case "running":
            case "restart":
                return "green";
            case "kill":
                return "orange";
            case "stop":
            case "die":
                return "red";
            default:
                return "red";
        }
    };

    // 根据容器状态动态设置按钮是否可用
    const isRunning = status === "start" || status === "running" || status === "restart";
    const isProcessing = status === "kill" || status === "create";
    //paused暂停 restarting重启中 exited停止  die运行错误死亡
    const isTerminating = status.toLowerCase() === "terminating";

    return (
        <div className="custom-card">
            <div className="card-header">
                <Typography className="card-title">{nickname}</Typography>
                <Dropdown menu={{ items }} placement="bottomRight" trigger={["click"]}>
                    <MoreOutlined style={{ fontSize: 20, cursor: "pointer" }} />
                </Dropdown>
            </div>
            <div className="card-body">
                <div className="server-name">
                    {server_name}{" "}
                    {is_admin && (
                        <>
                            <Button type="dashed" onClick={() => handleCopy(subuser_username)} >
                                复制账号
                            </Button>
                            <Button type="dashed" onClick={() => handleCopy(subuser_password)} >
                                复制密码
                            </Button>
                        </>
                    )}
                </div>
            </div>
            <div className="divider" />
            <div className="card-actions">
                <div className={`status-indicator ${getStatusColor(status)}`} />
                <Button onClick={handleRun} type="primary" disabled={isRunning || isProcessing || isTerminating}>
                    启动
                </Button>
                <Button onClick={handleStop} type="primary" danger>
                    停止
                </Button>
                <Button onClick={handleLog} type="primary" disabled={!isRunning}>
                    查看日志
                </Button>
                <Button onClick={handleSetting} type="primary">
                    修改配置
                </Button>
            </div>

            {/* 修改密码 Modal */}
            <Modal
                title={`修改密码 ${nickname} (${server_name})`}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                centered
                maskClosable={false}
            >
                <Form layout="vertical" onFinish={handleChangePassword}>
                    <Form.Item name="old_password" label="当前密码" rules={[{ required: true, message: "请输入当前密码" }]}>
                        <Input.Password placeholder="请输入当前密码" />
                    </Form.Item>
                    <Form.Item name="password" label="新密码" rules={[{ required: true, message: "请输入新密码" }]}>
                        <Input.Password placeholder="请输入新密码" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            提交修改
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 删除账户 Modal */}
            <Modal
                title={
                    <span>
                        <ExclamationCircleOutlined style={{ color: "red" }} /> 删除账户 {nickname} ({server_name})
                    </span>
                }
                open={isDeleteModalOpen}
                onCancel={() => setIsDeleteModalOpen(false)}
                onOk={handleDeleteAccount}
                okButtonProps={{ disabled: deleteCountdown > 0 }}
                okText={deleteCountdown > 0 ? `确认 (${deleteCountdown}s)` : "确认"}
                cancelText="取消"
                centered
                maskClosable={false}
            >
                <Typography.Text style={{ display: "block", color: "red" }}>删除后将数据清空。</Typography.Text>
            </Modal>
        </div>
    );
};

export default AccountCard;

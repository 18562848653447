import React, { useEffect, useState } from "react";
import NavBar from "@/components/Navbar";
import HomeCards from "@/components/HomeCards";
import AddAccount from "./AddAccount";
import { FloatButton, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./style.css";
import { GetSubUsers, GetQuota, subscribeToContainerStatus } from "@/store/modules/user";
import { Bind } from "@/store/modules/subUser";
import { useDispatch, useSelector } from "react-redux";

export const Home = () => {
    const dispatch = useDispatch();

    const { sub_users } = useSelector((state) => state.user.user);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const loadAccounts = async () => {
            try {
                await dispatch(GetSubUsers()).unwrap();
            } catch (err) {
                console.error("加载账户数据失败，请稍后重试！", err);
            }

            try {
                await dispatch(GetQuota()).unwrap();
            } catch (err) {
                console.error("加载配额失败，请稍后重试！", err);
            }
        };

        loadAccounts();
    }, [dispatch]);
    
    let eventSourceInstance = null;

    useEffect(() => {
        if (eventSourceInstance) {
            eventSourceInstance.close();
        }
    
        eventSourceInstance = subscribeToContainerStatus(dispatch);
    
        return () => {
            if (eventSourceInstance) {
                console.log("清理资源");
                eventSourceInstance.close();
                eventSourceInstance = null;
            }
        };
    }, [dispatch]);

    // 打开添加子账户 Modal
    const openAddAccountModal = () => {
        setIsModalOpen(true);
    };

    // 关闭 Modal
    const closeAddAccountModal = () => {
        setIsModalOpen(false);
    };

    // 添加子账户
    const handleAddAccount = async (values) => {
        try {
            const { username, password, server_name, token, server_id, uid } = values;
            await dispatch(Bind({ username, password, server_name, token, server_id, uid })).unwrap();

            message.success("子账户添加成功!");
            setIsModalOpen(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="home-container">
            {/* 顶部导航 */}
            <NavBar />

            {/* Account Cards */}
            <HomeCards sub_users={sub_users} is_admin={false}/>

            {/* 右下角的 FloatButton */}
            <FloatButton icon={<PlusCircleOutlined />} type="primary" tooltip="添加子账户" onClick={openAddAccountModal} />

            {/* 添加子账户 Modal */}
            <AddAccount isModalOpen={isModalOpen} onClose={closeAddAccountModal} onAddAccount={handleAddAccount} />
        </div>
    );
};

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown, Space, Button, Modal, Form, Input, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { selectQuota, ChangePassword, Logout } from "@/store/modules/user";
import { LeftOutlined } from "@ant-design/icons";
import "./style.css";
import logo from "./logo.gif";

const NavBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { is_admin } = useSelector((state) => state.user);

    // 打开修改密码 Modal
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    // 提交修改密码表单
    const handleChangePassword = async (values) => {
        try {
            const { old_password, password } = values;
            await dispatch(ChangePassword({ old_password, password })).unwrap();
            message.success("密码修改成功！");
            setIsModalOpen(false);
        } catch (error) {
            console.log(`修改密码失败: ${error}`);
        }
    };

    const logout = () => {
        dispatch(Logout());
    };

    const menuItems = [
        {
            key: "home",
            label: "首页",
            onClick: () => navigate("/home"),
        },
        {
            key: "help",
            label: "帮助",
            onClick: () => navigate("/help"),
        },
        ...(is_admin
            ? [
                  {
                      key: "admin",
                      label: <span style={{ color: "orange", fontWeight: "bold" }}>管理员</span>,
                      onClick: () => navigate("/admin/home"),
                  },
              ]
            : []),
        {
            key: "change-password",
            label: "修改密码",
            onClick: handleOpenModal,
        },
        {
            type: "divider",
        },
        {
            key: "logout",
            label: <span style={{ color: "red" }}>退出登录</span>,
            onClick: logout,
        },
    ];

    // 判断是否显示返回按钮
    const showBackButton = location.pathname !== "/home";

    return (
        <div className={`navbar ${showBackButton ? "with-back-button" : ""}`}>
            {/* 返回按钮 */}
            {showBackButton && (
                <div className="back-button">
                    <Button type="text" icon={<LeftOutlined style={{ fontSize: "18px" }} />} onClick={() => navigate(-1)} />
                </div>
            )}

            {/* 配额和头像部分 */}
            <div className="avatar-container">
                <Space align="center">
                    <div className="quota-display">
                        <span className="quota-text">{useSelector(selectQuota)}</span>
                    </div>
                    <Dropdown
                        menu={{
                            items: menuItems,
                        }}
                        trigger={["click"]}
                    >
                        <img src={logo} alt="头像" className="user-avatar" />
                    </Dropdown>
                </Space>
            </div>

            {/* 修改密码 Modal */}
            <Modal title="修改密码" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} centered maskClosable={false}>
                <Form layout="vertical" onFinish={handleChangePassword}>
                    <Form.Item name="old_password" label="当前密码" rules={[{ required: true, message: "请输入当前密码" }]}>
                        <Input.Password placeholder="请输入当前密码" />
                    </Form.Item>
                    <Form.Item name="password" label="新密码" rules={[{ required: true, message: "请输入新密码" }]}>
                        <Input.Password placeholder="请输入新密码" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            提交修改
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default NavBar;

import React from "react";
import { Collapse, Button, Typography, Carousel } from "antd";
import NavBar from "@/components/Navbar";
import "./style.css";

const { Panel } = Collapse;
const { Text } = Typography;

export const Help = () => {
    const createAccountLink = "https://wxshare1.37.com/dist/dzg/zzb-20220512/?hd_referer=jsb&game_id=784";
    const changePasswordLink = "https://h5.37.com/app_password.html";
    const xddqLink = "https://yxtgg4.37.com/";

    const images = [0, 1, 2].map((index) => require(`@/assets/help/${index}.png`));

    return (
        <div className="help-container">
            {/* 顶部导航 */}
            <NavBar />
            
            {/* 手风琴组件 */}
            <Collapse accordion className="help-collapse">
                <Panel header="如何微信小程序转APP" key="1">
                    <Text>
                        请在微信中打开本辅助，认真阅读攻略，访问下面链接。
                    </Text>
                    <Button type="link" onClick={() => window.open(createAccountLink, "_blank")}>
                        微信小程序转APP
                    </Button>
                    {/* 图片轮播 */}
                    <Carousel arrows infinite={false} className="help-carousel">
                        {images.map((image, index) => (
                            <div key={index} className="carousel-item">
                                <img src={image} alt={`帮助图片${index + 1}`} className="carousel-image" />
                            </div>
                        ))}
                    </Carousel>
                </Panel>
                <Panel header="如何修改密码" key="2">
                    <Text>
                        请访问以下链接修改密码：
                    </Text>
                    <Button type="link" onClick={() => window.open(changePasswordLink, "_blank")}>
                        点击修改密码
                    </Button>
                </Panel>
                <Panel header="如何认证身份证" key="3">
                    <Text>
                        1. 登录《寻道大千》游戏官网。
                        <br />
                        2. 点击页面右上角的登录，登录自己的账号。
                        <br />
                        3. 点击页面右上角的设置，选择实名认证。
                        <br />
                        4. 点击修改实名认证信息，填写正确的姓名和身份证号码，提交审核。
                        <br />
                        5. 审核通过，实名认证信息已经更改成功。
                        <br />
                    </Text>
                    <Button type="link" onClick={() => window.open(xddqLink, "_blank")}>
                        官网地址
                    </Button>
                </Panel>
                <Panel header="使用规则" key="4">
                    <Text>
                        <ul className="help-rules">
                            <li>
                                <strong>活动奖励及广告规则：</strong> 
                                未购买月卡/永久卡的用户需要关闭活动领奖励的开关。
                            </li>
                            <li>
                                <strong>新区使用规则(暂时)：</strong> 
                                新区玩家需达到合体以上再使用辅助功能，否则会面临封号风险。
                            </li>
                            <li>
                                <strong>停服更新规则：</strong> 
                                停服更新期间，必须停止所有辅助功能，否则会被永久封号。
                            </li>
                        </ul>
                    </Text>
                </Panel>
            </Collapse>
        </div>
    );
};

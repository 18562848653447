export const SubUserApiDefinition = {
    bind: {
        method: "post",
        url: "/api/sub-user/bind",
        description: "绑定子账号",
    },
    delete: {
        method: "delete",
        url: (urlParams) => `/api/sub-user/${urlParams.id}`,
        description: "删除子账号",
    },
    changePassword: {
        method: "put",
        url: (urlParams) => `/api/sub-user/${urlParams.id}/password`,
        description: "修改子账号密码",
    },
    getSetting: {
        method: "get",
        url: (urlParams) => `/api/sub-user/${urlParams.id}/setting`,
        description: "获取子账号游戏配置",
    },
    saveSetting: {
        method: "put",
        url: (urlParams) => `/api/sub-user/${urlParams.id}/setting`,
        description: "保存子账号游戏配置",
    },
    getServers: {
        method: "post",
        url: "/api/servers",
        description: "获取37服务器列表",
    },
};

export const AdminApiDefinition = {
    getUsers: {
        method: "get",
        url: `/api/admin/users`,
        description: "获取账户列表",
    },
    getSubUsers: {
        method: "get",
        url: `/api/admin/sub-users`,
        description: "获取子账户列表",
    },
    updateUserQuota: {
        method: "put",
        url: (urlParams) => `/api/admin/user/${urlParams.id}/quota`,
        description: "修改配额",
    },
    updateUserStatus: {
        method: "put",
        url: (urlParams) => `/api/admin/user/${urlParams.id}/status`,
        description: "修改状态",
    },
    updateUserPassword: {
        method: "put",
        url: (urlParams) => `/api/admin/user/${urlParams.id}/password`,
        description: "修改密码",
    },
    deleteUser: {
        method: "delete",
        url: (urlParams) => `/api/admin/user/${urlParams.id}`,
        description: "删除账号",
    },
}

export const homelandItems = [
    { id: 10001, name: "Lv1", src: require(`../../assets/items/item_2.webp`), group: "仙玉" },
    { id: 10002, name: "Lv2", src: require(`../../assets/items/item_2.webp`), group: "仙玉" },
    { id: 10003, name: "Lv3", src: require(`../../assets/items/item_2.webp`), group: "仙玉" },
    { id: 10004, name: "Lv4", src: require(`../../assets/items/item_2.webp`), group: "仙玉" },
    { id: 10005, name: "Lv5", src: require(`../../assets/items/item_2.webp`), group: "仙玉" },
    { id: 10008, name: "Lv1", src: require(`../../assets/items/item_1.webp`), group: "净瓶水" },
    { id: 10009, name: "Lv2", src: require(`../../assets/items/item_1.webp`), group: "净瓶水" },
    { id: 10010, name: "Lv3", src: require(`../../assets/items/item_1.webp`), group: "净瓶水" },
    { id: 10011, name: "Lv4", src: require(`../../assets/items/item_1.webp`), group: "净瓶水" },
    { id: 10012, name: "Lv5", src: require(`../../assets/items/item_1.webp`), group: "净瓶水" },
    { id: 10015, name: "Lv1", src: require(`../../assets/items/item_4.webp`), group: "琉璃珠" },
    { id: 10016, name: "Lv2", src: require(`../../assets/items/item_4.webp`), group: "琉璃珠" },
    { id: 10017, name: "Lv3", src: require(`../../assets/items/item_4.webp`), group: "琉璃珠" },
    { id: 10018, name: "Lv4", src: require(`../../assets/items/item_4.webp`), group: "琉璃珠" },
    { id: 10019, name: "Lv5", src: require(`../../assets/items/item_4.webp`), group: "琉璃珠" },
    { id: 10022, name: "Lv1", src: require(`../../assets/items/item_3.webp`), group: "灵石" },
    { id: 10023, name: "Lv2", src: require(`../../assets/items/item_3.webp`), group: "灵石" },
    { id: 10024, name: "Lv3", src: require(`../../assets/items/item_3.webp`), group: "灵石" },
    { id: 10025, name: "Lv4", src: require(`../../assets/items/item_3.webp`), group: "灵石" },
    { id: 10026, name: "Lv5", src: require(`../../assets/items/item_3.webp`), group: "灵石" },
    { id: 10029, name: "Lv1", src: require(`../../assets/items/item_0.webp`), group: "仙桃" },
    { id: 10030, name: "Lv2", src: require(`../../assets/items/item_0.webp`), group: "仙桃" },
    { id: 10031, name: "Lv3", src: require(`../../assets/items/item_0.webp`), group: "仙桃" },
    { id: 10032, name: "Lv4", src: require(`../../assets/items/item_0.webp`), group: "仙桃" },
    { id: 10033, name: "Lv5", src: require(`../../assets/items/item_0.webp`), group: "仙桃" },
    { id: 10036, name: "Lv1", src: require(`../../assets/items/item_5.webp`), group: "天衍令" },
    { id: 10037, name: "Lv2", src: require(`../../assets/items/item_5.webp`), group: "天衍令" },
    { id: 10038, name: "Lv3", src: require(`../../assets/items/item_5.webp`), group: "天衍令" },
    { id: 10039, name: "Lv4", src: require(`../../assets/items/item_5.webp`), group: "天衍令" },
    { id: 10040, name: "Lv5", src: require(`../../assets/items/item_5.webp`), group: "天衍令" },
    { id: 10043, name: "Lv1", src: require(`../../assets/items/item_6.webp`), group: "昆仑铁" },
    { id: 10044, name: "Lv2", src: require(`../../assets/items/item_6.webp`), group: "昆仑铁" },
    { id: 10045, name: "Lv3", src: require(`../../assets/items/item_6.webp`), group: "昆仑铁" },
    { id: 10046, name: "Lv4", src: require(`../../assets/items/item_6.webp`), group: "昆仑铁" },
    { id: 10047, name: "Lv5", src: require(`../../assets/items/item_6.webp`), group: "昆仑铁" }
];

export const mallItems = [
    { id: 110000013, name: "五色石x1", group: "坊市商店" },
    { id: 110000007, name: "净瓶水x1", group: "坊市商店" },
    { id: 250000004, name: "1级印记礼包x1", group: "群英商店" },
    { id: 250000003, name: "道书x1", group: "群英商店" },
    { id: 250000001, name: "仙桃x50", group: "群英商店" },
    { id: 230000005, name: "净瓶水x5", group: "妖盟商店" },
    { id: 230000007, name: "道书x3", group: "妖盟商店" },
    { id: 230000006, name: "净瓶水x10", group: "妖盟商店" },
    { id: 230000001, name: "仙桃x50", group: "妖盟商店" },
    { id: 230000002, name: "仙桃x100", group: "妖盟商店" },
    { id: 230000014, name: "玄黄果x200", group: "妖盟商店" },
    { id: 230000008, name: "传说随机精怪碎片x1", group: "妖盟商店" },
    { id: 230000011, name: "仙桃x10", group: "妖盟商店" },
    { id: 450000001, name: "传说自选精怪碎片x1", group: "荣誉商店" },
    { id: 450000002, name: "自选传说法宝碎片x1", group: "荣誉商店" },
    { id: 450000003, name: "天衍令x20", group: "荣誉商店" },
    { id: 450000004, name: "寻宝罗盘x1", group: "荣誉商店" },
    { id: 450000005, name: "召唤令x1", group: "荣誉商店" },
    { id: 450000006, name: "随机炼化宝箱x5", group: "荣誉商店" },
    { id: 450000007, name: "召唤令x1", group: "荣誉商店" },
    { id: 450000008, name: "昆仑铁x20", group: "荣誉商店" },
    { id: 450000009, name: "灵兽果x200", group: "荣誉商店" },
    { id: 450000010, name: "庚金x10", group: "荣誉商店" },
    { id: 450000011, name: "道书x1", group: "荣誉商店" },
    { id: 450000012, name: "先天灵草x10", group: "荣誉商店" },
    { id: 590000001, name: "自选神话灵兽碎片x1", group: "九幽秘宝" },
    { id: 590000002, name: "随机传说法宝碎片x1", group: "九幽秘宝" },
    { id: 590000003, name: "悟道石x10", group: "九幽秘宝" },
    { id: 590000004, name: "天衍令x20", group: "九幽秘宝" },
    { id: 590000005, name: "寻宝罗盘x1", group: "九幽秘宝" },
    { id: 590000006, name: "召唤令x1", group: "九幽秘宝" },
    { id: 590000007, name: "随机炼化宝箱x5", group: "九幽秘宝" },
    { id: 590000008, name: "昆仑铁x10", group: "九幽秘宝" },
    { id: 590000009, name: "灵兽果x100", group: "九幽秘宝" },
    { id: 590000010, name: "道书x1", group: "九幽秘宝" },
    { id: 920000001, name: "随机传说法宝碎片x25", group: "三界商店" },
    { id: 920000002, name: "仙桃x300", group: "三界商店" },
    { id: 920000003, name: "悟道石x10", group: "三界商店" },
    { id: 920000004, name: "悟道石x10", group: "三界商店" },
    { id: 920000005, name: "悟道石x10", group: "三界商店" },
    { id: 920000006, name: "寻宝罗盘x1", group: "三界商店" },
    { id: 920000007, name: "御灵石x10", group: "三界商店" },
    { id: 920000008, name: "召唤令x1", group: "三界商店" },
    { id: 920000009, name: "天衍令x20", group: "三界商店" },
    { id: 920000010, name: "灵兽果x500", group: "三界商店" },
    { id: 920000011, name: "道书x10", group: "三界商店" },
]

export const avatarOptions = [
    { label: "元体", value: 0 },
    { label: "阳神", value: 1 },
    { label: "阴身", value: 2 }
];

export const skillDict = {
    1001: "攻击",
    1011: "抵抗击晕",
    1012: "抵抗暴击",
    1013: "抵抗连击",
    1014: "抵抗闪避",
    1015: "抵抗反击",
    1016: "抵抗吸血",
    1017: "最终增伤",
    1018: "最终减伤",
    1021: "强化治疗",
    1022: "弱化治疗",
    1023: "强化灵兽",
    1024: "弱化灵兽",
};

// 灵兽字典
export const wishPetDict = {
    114001: "应龙",
    114002: "年",
    114003: "无支祁",
    114004: "九尾狐",
    114005: "狻猊",
    114006: "计蒙",
    114007: "鸾鸟",
    115001: "青龙",
    115002: "白虎",
    115003: "朱雀",
    115004: "玄武",
    115005: "麒麟",
};

// 属性字典
export const attributeDict = {
    5: "击晕",
    6: "暴击",
    7: "连击",
    8: "闪避",
    9: "反击",
    10: "吸血",
    11: "抗击晕",
    12: "抗暴击",
    13: "抗连击",
    14: "抗闪避",
    15: "抗反击",
    16: "抗吸血",
};

export const anyOption = "任意"; // 代表任意选项

// 装备品质字典
export const qualityDict = {
    1: "凡品",
    2: "下品",
    3: "中品",
    4: "上品",
    5: "极品",
    6: "仙品",
    7: "完美",
    8: "先天",
    9: "至宝",
    ...generateItems("灵宝", 10, 5, "星"),
    ...generateItems("真宝", 15, 5, "星"),
    ...generateItems("灵器", 20, 5, "星"),
    ...generateItems("神器", 25, 5, "星"),
    ...generateItems("仙器", 30, 5, "星"),
    ...generateItems("道器", 35, 5, "星"),
    ...generateItems("九天", 40, 5, "重"),
    ...generateItems("太乙", 45, 5, "重"),
    ...generateItems("混元", 50, 5, "重"),
    ...generateItems("大罗", 55, 5, "重"),
    ...generateItems("造化", 60, 5, "重"),
    ...generateItems("无量", 65, 5, "重"),
    ...generateItems("不灭", 70, 5, "重"),
    ...generateItems("元始", 75, 5, "重"),
    ...generateItems("混沌", 80, 5, "重"),
    ...generateItems("祖炁", 85, 5, "重"),
    ...generateItems("无极", 90, 5, "重"),
};

function generateItems(prefix, start, count, suffix) {
    const items = {};
    for (let i = 1; i <= count; i++) {
        items[start + i - 1] = `${prefix}${i}${suffix}`;
    }
    return items;
}

// 属性字典
export const talentAttributeDict = {
    4: "敏捷",
    11: "抗击晕",
    12: "抗暴击",
    13: "抗连击",
    14: "抗闪避",
    15: "抗反击",
    16: "抗吸血",
};

// 技能字典
export const talentSkillDict = {
    50001: "玄武(吸血)",
    50002: "白虎(反击)",
    50003: "螣蛇(连击)",
    50004: "勾陈(闪避)",
    50005: "青龙(暴击)",
    50006: "朱雀(击晕)",
};

// 品质字典
export const talentQualityDict = {
    1: "凡品",
    2: "下品",
    3: "中品",
    4: "上品",
    5: "极品",
    6: "仙品",
    7: "完美",
    8: "先天",
    9: "至宝",
};

// 活动字典
export const activityDict = {
    25: "运势",
    50: "蛮荒妖域",
    53: "妖盟乱斗",
    60: "妖盟攻城战",
    61: "问道盛会",
    64: "觅宝活动", // 妖市觅宝,九皇觅宝,仙树祈愿,福春觅宝,龙宫寻宝
    68: "蓬莱仙岛",
    71: "仙兔活动", // 仙兔开宝,北冥寻宝,秘境探宝,上元灯夜
    95: "九幽争霸",
    108: "龙窟秘境",
    116: "妖灵宝塔",
    119: "炼器大会",
    130: "召唤神龙",
    131: "四圣宝域",
    134: "炼化法宝",
    150: "仙域商途",
    155: "哪吒降妖",
    163: "黄山仙径",
    171: "飞剑夺宝",
    172: "轮回秘境",
    173: "守卫仙树",
    174: "玄尘忆梦",
};

export const daysOfWeek = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]

export const timeUnits = [
    { label: "秒", value: 1000 },
    { label: "分", value: 60000 },
    { label: "时", value: 3600000 },
];
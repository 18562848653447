import React, { useState, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import { Collapse, Popover } from "antd-mobile";
import { QuestionCircleOutline } from "antd-mobile-icons";
import { DndProviderPreview, usePreview } from "react-dnd-preview"; // Add preview
import "./ShuttleBox.css";

const DRAG_ITEM_TYPE = "SHUTTLE_ITEM";

const MovableItem = ({ item, index, currentColumn, moveItemHandler }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: DRAG_ITEM_TYPE,
        hover: (draggedItem) => {
            if (draggedItem.index === index) return;

            moveItemHandler(draggedItem, currentColumn, index);
            draggedItem.index = index;
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        type: DRAG_ITEM_TYPE,
        item: { ...item, index, currentColumn },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // 绑定 preview
    drag(drop(ref));
    preview(ref);

    const opacity = isDragging ? 0.5 : 1;

    // 渲染角标元素
    const renderWithBadge = () => (
        <div className="image-badge-container">
            <img src={item.src} alt={item.name || `Item ${item.id}`} className="shuttle-image" />
            <span className="badge">{item.name}</span>
        </div>
    );

    return (
        <div ref={ref} className="shuttle-item" style={{ opacity }}>
            {item.src ? renderWithBadge() : `(${item.group})${item.name}`}
        </div>
    );
};

// 用于显示拖动时的预览
const CustomDragPreview = () => {
    const { item, style } = usePreview();

    if (!item) return null;

    return (
        <div className="drag-preview" style={style}>
            {item.src ? <img src={item.src} alt={item.name || `Item ${item.id}`} className="shuttle-image" /> : item.name}
        </div>
    );
};

const Column = ({ title, items, column, moveItemHandler, grouped = false, helpText = null }) => {
    const [, drop] = useDrop({
        accept: DRAG_ITEM_TYPE,
        drop: (draggedItem) => {
            if (draggedItem.currentColumn !== column) {
                moveItemHandler(draggedItem, column, items.length);
            }
        },
    });

    const helpIcon = helpText ? (
        <Popover
            content={helpText}
            mode="dark"
            trigger="click"
        >
            <span className="column-help-icon" onClick={(e) => e.stopPropagation()}>
                <QuestionCircleOutline />
            </span>
        </Popover>
    ) : null;

    if (grouped) {
        const groupedItems = items.reduce((groups, item) => {
            const group = item.group || "默认分组";
            if (!groups[group]) groups[group] = [];
            groups[group].push(item);
            return groups;
        }, {});

        return (
            <div ref={drop} className="shuttle-column">
                <div className="column-header">
                    <h3>{title}</h3>
                    {helpIcon}
                </div>
                <Collapse accordion>
                    {Object.entries(groupedItems).map(([groupName, groupItems]) => (
                        <Collapse.Panel
                            key={groupName}
                            title={<span className="collapse-title">{groupName}</span>} // 添加自定义样式
                            className="collapse-panel"
                        >
                            {groupItems.map((item, index) => (
                                <MovableItem
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    currentColumn={column}
                                    moveItemHandler={moveItemHandler}
                                />
                            ))}
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </div>
        );
    }

    return (
        <div ref={drop} className="shuttle-column">
            <div className="column-header">
                <h3>{title}</h3>
                {helpIcon}
            </div>
            {items.map((item, index) => (
                <MovableItem key={item.id} item={item} index={index} currentColumn={column} moveItemHandler={moveItemHandler} />
            ))}
        </div>
    );
};

export const ShuttleBox = ({
    availableItems = [],
    selectedItems = [],
    labels = { available: "待选择", selected: "已选择" },
    helpText = null,
    onChange,
}) => {
    const [items, setItems] = useState([
        ...availableItems.map((item, index) => ({ ...item, column: "available", index })),
        ...selectedItems.map((item, index) => ({ ...item, column: "selected", index })),
    ]);

    const moveItemHandler = (draggedItem, targetColumn, targetIndex) => {
        setItems((prev) => {
            const updatedItems = [...prev];
            const draggedIndex = updatedItems.findIndex((item) => item.id === draggedItem.id);
            const [movedItem] = updatedItems.splice(draggedIndex, 1);

            movedItem.column = targetColumn;
            updatedItems.splice(targetIndex, 0, movedItem);

            const selectedItems = updatedItems.filter((item) => item.column === "selected");
            onChange?.(selectedItems.map((item) => item.id));

            return updatedItems.map((item, idx) => ({ ...item, index: idx }));
        });
    };

    const availableItemsList = items.filter((item) => item.column === "available");
    const selectedItemsList = items.filter((item) => item.column === "selected");

    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <CustomDragPreview />
            <div className="shuttle-container">
                <Column
                    title={labels.available}
                    items={availableItemsList}
                    column="available"
                    moveItemHandler={moveItemHandler}
                    grouped
                    helpText={helpText?.available}
                />
                <Column
                    title={labels.selected}
                    items={selectedItemsList}
                    column="selected"
                    moveItemHandler={moveItemHandler}
                    helpText={helpText?.selected}
                />
            </div>
        </DndProvider>
    );
};

import React from "react";
import { Empty } from "antd";
import AccountCard from "./AccountCard";
import "./style.css";

const HomeCards = ({ sub_users, is_admin }) => {
    if (!sub_users || sub_users.length === 0) {
        return (
            <div className="no-accounts">
                <Empty description={false} />
            </div>
        );
    }

    return (
        <div className="home-content">
            {sub_users.map((i) => (
                <AccountCard key={i.id} id={i.id} is_admin={is_admin}/>
            ))}
        </div>
    );
};

export default HomeCards;
